import { useEffect, useState } from 'react';
import useNavigatorOnLine from '../../../hooks/useNavigatorOnline';
import { useConnectionStore } from '../../../store/new/connection-state';
import { ConnectionStateEnum } from '../../../store/new/connection-state/connection-state.interface';

const ConnectionInfo = () => {
  const [show, setShow] = useState(true);
  const isOnline = useNavigatorOnLine();
  const connectionState = useConnectionStore((state) => state.connectionState);
  const connectionStateCustomer = useConnectionStore((state) => state.connectionStateCustomer);
  const TEXT_ONLY_AGENT_POOR_CONNECTION =
    'Koneksi internet sedang tidak optimal. Silakan cek koneksi internet Anda.';
  const TEXT_ONLY_CUSTOMER_POOR_CONNECTION =
    'Koneksi pelanggan sedang tidak optimal. Mohon informasikan kepada pelanggan.';
  const TEXT_AGENT_CUSTOMER_POOR_CONNECTION =
    'Koneksi anda dan pelanggan sedang tidak optimal. Mohon cek dan informasikan kepada pelanggan.';

  useEffect(() => {
    if (isOnline || Object.values(ConnectionStateEnum).includes(connectionState)) setShow(true);
  }, [connectionState, isOnline]);
  const isAgentConnectionPoor = connectionState === ConnectionStateEnum.POOR;
  const isCustConnectionPoor = connectionStateCustomer === ConnectionStateEnum.POOR;
  return (
    <div
      role="dialog"
      className={`${
        (!isOnline || isAgentConnectionPoor || isCustConnectionPoor) && show
          ? 'translate-y-8 opacity-100'
          : '-translate-y-full opacity-0'
      } absolute left-1/2 top-0 z-[200] w-fit -translate-x-1/2 rounded-xl 
        bg-orange-danger-light px-6 py-0.5 text-white transition-all duration-300`}>
      <div className="text-left text-sm">
        {!isOnline ? (
          <p>Menghubungkan kembali ke internet. Selagi menunggu, mohon cek internet Anda.</p>
        ) : (
          <p>
            {isAgentConnectionPoor && isCustConnectionPoor
              ? TEXT_AGENT_CUSTOMER_POOR_CONNECTION
              : isCustConnectionPoor
              ? TEXT_ONLY_CUSTOMER_POOR_CONNECTION
              : TEXT_ONLY_AGENT_POOR_CONNECTION}
          </p>
        )}
      </div>
    </div>
  );
};

export default ConnectionInfo;
