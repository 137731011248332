import { useEffect, useState } from 'react';
import { ConversationInterface } from '../../card/chat-message/chat-message.interface';
import { RoleEnum } from '../../../static/role';
import { useUserStatusStore } from '../../../store/user-state';
import { CommunicationTypeEnum } from '../../../static/communication-type';

const IncomingCallItems = (props: {
  items: ConversationInterface;
  handleActiveCall: (data: ConversationInterface) => void;
  assignedConversationList: ConversationInterface[];
}) => {
  const { items, handleActiveCall, assignedConversationList } = props;
  const { userRole } = useUserStatusStore();
  const [isVisible, setIsVisible] = useState(false);

  const communicationType = items.communicationType;
  const conversationTypeText = {
    [CommunicationTypeEnum.VIDEO]: 'panggilan',
    [CommunicationTypeEnum.CHAT]: 'percakapan'
  }[communicationType as CommunicationTypeEnum.CHAT | CommunicationTypeEnum.VIDEO];

  useEffect(() => {
    if (userRole === RoleEnum.SUPERVISOR) {
      setIsVisible(true);
    } else {
      setIsVisible(assignedConversationList && assignedConversationList.length > 0);
    }
    // eslint-disable-next-line
  }, [assignedConversationList, items?.communicationType]);

  return (
    <div
      className={`flex transform items-center rounded-lg bg-orange-danger px-6 py-4 font-bold transition-all duration-500 ease-in-out ${
        isVisible ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'
      }`}>
      <span className="flex text-white">
        {conversationTypeText} masuk : {items?.name}
      </span>
      {userRole === RoleEnum.AGENT && (
        <button
          onClick={() => handleActiveCall(items)}
          className="cursor-pointer border-transparent bg-transparent font-bold text-white underline">
          Jawab
        </button>
      )}
    </div>
  );
};
export default IncomingCallItems;
