import { Icon, Paragraph } from '@squantumengine/horizon';

import ButtonRefreshHighlight from './button-refresh-highlight';
import { LocalStorageKey } from '../../../static/local-storage';

interface ButtonRefreshProps {
  lastUpdate: string;
  onClick: () => void;
}

const ButtonRefresh = ({ onClick, lastUpdate }: ButtonRefreshProps) => {
  const shouldShowHighlight = !localStorage.getItem(LocalStorageKey.SQECC_CONV_REFRESH_BTN);

  return (
    <>
      {shouldShowHighlight && <ButtonRefreshHighlight />}
      <div
        id="button-refresh"
        className="flex items-center justify-between gap-3 rounded-lg border border-solid border-neutral-300 bg-[#F9F9F9] p-3">
        <Paragraph weight="semibold" size="s">
          Last updated: {lastUpdate} WIB
        </Paragraph>
        <div
          data-testid="button-refresh"
          className="flex cursor-pointer items-center gap-1 hover:opacity-70"
          onClick={onClick}>
          <Paragraph weight="semibold" className="text-[#1A936F]">
            Refresh
          </Paragraph>
          <Icon name="sync" color="#1A936F" size="sm" />
        </div>
      </div>
    </>
  );
};

export default ButtonRefresh;
