import React from 'react';
import { StatusKYCEnum } from '../../kyc/kyc.interface';
import { ConversationStatusEnum } from '../../../static/message-event';
import { ConversationContextEnum } from '../../../static/communication-type';
import { Paragraph } from '@squantumengine/horizon';
import { getStatusClass, getStatusText } from '../../../utils/kyc';

interface Props {
  kycStatus: StatusKYCEnum;
  conversationStatus: ConversationStatusEnum;
  conversationContext: ConversationContextEnum;
}

const KYCStatus: React.FC<Props> = ({ kycStatus, conversationStatus, conversationContext }) => {
  const getDisplayText = () => {
    const isResolvedAndKYC =
      conversationStatus === ConversationStatusEnum.RESOLVED &&
      conversationContext === ConversationContextEnum.KYC;

    if (isResolvedAndKYC) {
      return getStatusText(kycStatus);
    }

    return '';
  };
  return (
    <div className="mb-1 flex flex-row items-center justify-between">
      <Paragraph className="text-neutral-600">Status KYC</Paragraph>
      <Paragraph className={`truncate ${getStatusClass(kycStatus)}`}>{getDisplayText()}</Paragraph>
    </div>
  );
};

export default KYCStatus;
