import { Collapse, CollapseProps, Form } from 'antd';
import { useEffect, useRef } from 'react';
import TeamInformation from './team-information';
import TeamConfig from './team-config';
import useTeamCreateEditStore from '../../store';
import { WebhookIdentifierEnum } from '../../../../../static/communication-type';
import { ChannelEnum } from '../../type';
import { WhatsAppSupportModeEnum } from '../../../../../shared/types/whatsapp.interface';

export enum MethodConvEnum {
  CHAT = 'CHAT',
  CALL = 'AUDIO_VIDEO'
}

export enum TypeCallEnum {
  VOICE = 'AUDIO',
  VIDEO = 'VIDEO',
  VOICE_VIDEO = 'AUDIO_VIDEO'
}

export enum TypeConvEnum {
  GENERAL = 'GENERAL',
  MANUAL_KYC = 'MANUAL_KYC'
}

export const typeConvOptions = [
  { label: 'Umum', value: 'GENERAL' },
  { label: 'Manual KYC', value: 'MANUAL_KYC' }
];

export const typeWhatsappConvOptions = [
  { label: 'Agen', value: 'AGENT' },
  { label: 'Chatbot', value: 'BOT' },
  { label: 'Chatbot+Agen', value: 'BOTH' }
];

type Props = {
  getFormRef: (form: any) => void;
};

const customRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
  <>
    {label}
    {required && <span style={{ color: 'red' }}>*</span>}
  </>
);

export default function FormTeamConfiguration({ getFormRef }: Props) {
  const [form] = Form.useForm();
  const channelForm = Form.useWatch('channelType', form);
  const typeConversationForm = Form.useWatch('conversationContext', form);
  const inboxNameForm = Form.useWatch('inboxName', form);
  const supportModeForm = Form.useWatch('supportMode', form);

  const {
    formTeamConfiguration,
    setFormTeamConfiguration,
    channelType,
    setChannelType,
    formWhatsapp,
    setFormWhatsapp
  } = useTeamCreateEditStore();

  const isRendered = useRef(false);
  const isManualKYC = typeConversationForm === TypeConvEnum.MANUAL_KYC;
  const isSupportModeChatbot = formWhatsapp.inboxConfig.supportMode === WhatsAppSupportModeEnum.BOT;
  const isChannelWhatsapp = channelType === ChannelEnum.WHATSAPP;

  useEffect(() => {
    if (isRendered.current) return;
    isRendered.current = true;
    form.setFieldsValue({
      ...formTeamConfiguration,
      channelType,
      webhookIdentifier: formTeamConfiguration.webhookIdentifier ?? WebhookIdentifierEnum.NONE,
      credential: formTeamConfiguration.credential ?? WebhookIdentifierEnum.NONE,
      durationReminder: formTeamConfiguration?.durationReminder
    });
    getFormRef(form);
  }, [channelType, form, formTeamConfiguration, getFormRef]);

  const isChannelFilled = !!channelForm;

  const typeCallOptions = [
    { label: 'Hanya Panggilan Suara', value: 'AUDIO', disabled: isManualKYC },
    { label: 'Hanya Panggilan Video', value: 'VIDEO' },
    { label: 'Panggilan Suara + Video', value: 'AUDIO_VIDEO', disabled: isManualKYC }
  ];

  const itemsTeamInfo: CollapseProps['items'] = [
    {
      key: '1',
      label: <h3 className="m-0 p-0 text-lg font-bold">Informasi Tim</h3>,
      children: <TeamInformation form={form} typeCallOptions={typeCallOptions} />
    }
  ];

  const itemsTeamConfig: CollapseProps['items'] = [
    {
      key: '1',
      label: <h3 className="m-0 p-0 text-lg font-bold">Pengaturan Teknis</h3>,
      children: <TeamConfig form={form} />
    }
  ];

  const shouldShowConfig = isChannelFilled && !(isChannelWhatsapp && isSupportModeChatbot);

  useEffect(() => {
    if (channelForm === ChannelEnum.WHATSAPP) {
      setFormWhatsapp({
        ...formWhatsapp,
        inboxName: inboxNameForm,
        inboxConfig: {
          supportMode: supportModeForm,
          decisionTreeId: ''
        }
      });
      setChannelType(channelForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxNameForm, supportModeForm, channelForm]);

  return (
    <Form
      name="team-information"
      layout="vertical"
      form={form}
      autoComplete="off"
      size="large"
      requiredMark={customRequiredMark}
      onValuesChange={(data) => {
        setFormTeamConfiguration({ ...formTeamConfiguration, ...data });
        setChannelType(data.channelType ?? channelType);
      }}
      className="flex flex-col space-y-8 px-4 pb-4">
      <div className="rounded-xl bg-white p-6 shadow-md">
        <Collapse
          className="[&>.cc-collapse-item>.cc-collapse-content>.cc-collapse-content-box]:!p-2 [&>.cc-collapse-item>.cc-collapse-header]:!p-0"
          expandIconPosition="end"
          ghost
          bordered={false}
          items={itemsTeamInfo}
          defaultActiveKey={['1']}
        />
      </div>
      {shouldShowConfig && (
        <div className="rounded-xl bg-white p-6 shadow-md">
          <Collapse
            className="[&>.cc-collapse-item>.cc-collapse-content>.cc-collapse-content-box]:!px-0 [&>.cc-collapse-item>.cc-collapse-header]:!p-0"
            expandIconPosition="end"
            ghost
            bordered={false}
            items={itemsTeamConfig}
            defaultActiveKey={['1']}
          />
        </div>
      )}
    </Form>
  );
}
