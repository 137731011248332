import { Button, Modal, Typography } from 'antd';
import { ChooseTypeEnum, ScreenshotModalInterface } from '../kyc.interface';
import dataURLtoFile from '../../../utils/dataUrltoFile';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { useState } from 'react';
import { postDocument } from '../../../api';
import { postWidgetMessage } from '../../../config/send-messages';
import { useChatListStore } from '../../../store/chat-list';
import dayjs from 'dayjs';
import { withErrorHandling } from '../../../utils/error-handling';
import { Error } from '../../../shared/types/error-handling.interface';
import useCCToaster from '../../../hooks/useCCToaster';
import { ResultErrorEnum } from '../../../static/error-response';
import { useConversationDocument } from '../../../api/hooks/kyc/useConversationDocument';

function ScreenshotModal(props: ScreenshotModalInterface) {
  const { open, handleOk, handleCancel, canvasRefP, canvasRefL, img } = props;

  const { Title, Text } = Typography;
  const { appendKtpPreviews, appendSelfiePreviews } = useSelectedCustomerStore();
  const { selectedCall, setIsNewAttachment } = useChatListStore();

  const [isUploading, setIsUploading] = useState(false);
  const { contextHolder, openNotification } = useCCToaster({ position: 'top', timeout: 3 });
  const { refetch: refetchDocument } = useConversationDocument(
    selectedCall?.conversationId || undefined
  );

  const getDoc = (res: { id: string; name: string; url: string }, docType: ChooseTypeEnum) => {
    return {
      documentId: res.id,
      documentName: res.name,
      documentType: docType,
      documentUrl: res.url
    };
  };

  const selectScreenshot = async (type: ChooseTypeEnum) => {
    if (isUploading || !selectedCall || !img) return;

    setIsUploading(true);

    let file = withErrorHandling(() =>
      dataURLtoFile(img.src, `${type}-${selectedCall.conversationId}-${dayjs().toISOString()}.png`)
    );

    if ((file as Error).error) {
      openNotification({ label: ResultErrorEnum.GENERAL_ERROR, variant: 'secondary' });
      setIsUploading(false);
      return;
    }

    const res = await postDocument(file);

    if (!res) return;

    const doc = getDoc(res, type);
    await postWidgetMessage('', [doc], 'TEXT', selectedCall.conversationId || '');

    if (type === ChooseTypeEnum.KTP) appendKtpPreviews(doc);
    if (type === ChooseTypeEnum.SELFIE) appendSelfiePreviews(doc);

    refetchDocument();
    setIsNewAttachment(true);
    setIsUploading(false);
    handleOk();
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={
          <Title level={3} className="m-0 p-0">
            Cek hasil screenshot
          </Title>
        }
        open={open}
        onOk={handleOk}
        width={600}
        confirmLoading={isUploading}
        footer={null}
        forceRender
        centered
        onCancel={handleCancel}>
        <div className="flex flex-col gap-4">
          <Text className="text-base">
            Sebelum menyimpan, pastikan bagian KTP dan wajah pelanggan tidak blur atau terpotong.
          </Text>
          <div className="w-full">
            <div id="snapshot" className="flex h-80 w-full justify-center rounded-3xl"></div>
            <canvas
              width={640}
              height={480}
              ref={canvasRefL}
              id="capture"
              className="hidden"></canvas>
            <canvas
              width={480}
              height={640}
              ref={canvasRefP}
              id="capture"
              className="hidden"></canvas>
          </div>
          <div className="flex w-full flex-row items-center justify-between gap-4">
            <Button
              loading={isUploading}
              onClick={() => selectScreenshot(ChooseTypeEnum.KTP)}
              type="primary"
              shape="default"
              htmlType="button"
              className="manual-kyc-btn w-1/2 self-center rounded-full border-black hover:text-white">
              <p className={`manual-kyc-p text-base font-semibold`}>Pilih sebagai KTP</p>
            </Button>
            <Button
              loading={isUploading}
              onClick={() => selectScreenshot(ChooseTypeEnum.SELFIE)}
              type="primary"
              shape="default"
              htmlType="button"
              className="manual-kyc-btn w-1/2 self-center rounded-full border-black hover:text-white">
              <p className={`manual-kyc-p text-base font-semibold`}>Pilih sebagai Selfie</p>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ScreenshotModal;
