import { Icon, Paragraph } from '@squantumengine/horizon';
import { Button } from 'antd';
import PreviewImage from './preview-image';

interface CardCompareImageProps {
  title: string;
  previewImages: string[];
  handleAddPreview: () => void;
  onChange: (imgIndex: number) => void;
  onChoose: (imgIndex: number) => void;
  showAddButton: boolean;
}

const CardCompareImage = ({
  title,
  previewImages,
  showAddButton = true,
  handleAddPreview,
  onChange,
  onChoose
}: CardCompareImageProps) => {
  const altPrefix = title.toLowerCase().replace(/\s+/g, '-');

  return (
    <div className="flex w-full flex-col gap-4 rounded-xl border border-solid border-black p-4">
      <Paragraph size="l" weight="semibold">
        {title}
      </Paragraph>

      {previewImages.map((image, index) => (
        <div key={index}>
          <PreviewImage
            src={image}
            alt={altPrefix + '-preview-image-1'}
            onChange={() => onChange(index)}
            onChoose={() => onChoose(index)}
          />
        </div>
      ))}

      {showAddButton && (
        <Button
          type="primary"
          shape="default"
          htmlType="button"
          className="manual-kyc-btn self-center rounded-xl border-black hover:text-white"
          onClick={handleAddPreview}>
          <div className="flex flex-row items-center gap-2">
            <p className={`manual-kyc-p text-xs font-semibold xl:text-base`}> + Tambah Preview</p>
            <Icon name="image" />
          </div>
        </Button>
      )}
    </div>
  );
};

export default CardCompareImage;
