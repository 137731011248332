import frameKtp from '../../assets/frame/frameKtp.svg';
import frameKtpSelfie from '../../assets/frame/frameKtpSelfie.svg';
import frameSelfie from '../../assets/frame/frameSelfie.svg';
import { PhotoTypeEnum } from '../kyc/kyc.interface';

export default function Frame(props: { frame: string }) {
  const { frame } = props;

  const selectedFrame = () => {
    switch (frame) {
      case PhotoTypeEnum.KTP:
        return frameKtp;
      case PhotoTypeEnum.SELFIE:
        return frameSelfie;
      case PhotoTypeEnum.BOTH:
        return frameKtpSelfie;
      default:
        return '';
    }
  };

  return (
    <div className="absolute left-1/2 z-10 flex h-full w-max  -translate-x-1/2  flex-col items-center justify-center">
      {selectedFrame() && (
        <div className="absolute bottom-0 z-10 flex h-full justify-center ">
          <img className="h-full !max-w-fit" src={selectedFrame()} alt={frame} />
        </div>
      )}
    </div>
  );
}
