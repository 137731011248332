import { ImageOrientationEnum, StatusKYCEnum } from '../components/v2/kyc/kyc.interface';

export const getStatusText = (status: string) => {
  switch (status) {
    case StatusKYCEnum.COMPLETED:
      return 'Disetujui';
    case StatusKYCEnum.FAILED:
      return 'Ditolak';
    default:
      return 'Ditunda';
  }
};

export const getStatusClass = (status: string) => {
  switch (status) {
    case StatusKYCEnum.COMPLETED:
      return 'text-blue-500';
    case StatusKYCEnum.FAILED:
      return 'text-red-500';
    default:
      return 'text-orange-500';
  }
};

export const checkImageOrientation = (
  imageUrl: string
): Promise<ImageOrientationEnum> => {
  return new Promise<ImageOrientationEnum>((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      if (width > height) {
        resolve(ImageOrientationEnum.LANDSCAPE);
      } else {
        resolve(ImageOrientationEnum.PORTRAIT);
      }
    };

    img.onerror = function () {
      reject(new Error('Failed to load image.'));
    };

    img.src = imageUrl;
  });
};

