import { FormInstance } from 'antd';
import { UserResponse } from '../../../components/card/user/user.interface';

export const InvTeamStatusEnum = {
  INVITED: 'INVITED',
  ACTIVE: 'ACTIVE',
  NON_ACTIVE: 'NONACTIVE',
  EXPIRED: 'EXPIRED',
  BLOCKED: 'BLOCKED',
  NULL: 'null'
};

export const LoginOptionsEnum = {
  EMAIL: 'email',
  USERNAME: 'username'
};

export const SendLoginOptionsEnum = {
  EMAIL_AGENT: 'email',
  WA_AGENT: 'whatsapp',
  EMAIL_SUPERVISOR: 'email_spv'
};

export interface TeamUserModalInterface {
  data: UserResponse;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
}

export interface UserBUModalInterface {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inviteUser: (organizationId: string) => Promise<void>;
  setSelectedBU?: React.Dispatch<
    React.SetStateAction<
      | {
          key: string;
          label: string;
        }
      | undefined
    >
  >;
}

export interface UserConfirmationModalInterface {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<UserResponse>>;
  selectedData: UserResponse;
}

export interface UserEditModalInterface {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
  selectedUser: UserResponse;
}

export const roleOptions = [
  { value: 'agent', label: 'Agen' },
  { value: 'supervisor', label: 'Supervisor' }
];

export const thirdPartyOptions = [{ value: 'CENTRIX', label: 'CENTRIX' }];

export interface UpdateUserPayloadParam {
  agentId?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phonNumber: string;
  role?: string;
  employeeId: string;
  status: string;
  accountId?: string;
}
