import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { Alert, Button, Form, Row } from 'antd';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useSelectedCustomerStore } from '../../store/customer-state';
import { useChatListStore } from '../../store/chat-list';
import {
  ChooseTypeEnum,
  ChoosItemEnum,
  KYCPageEnum,
  ManualKYCInterface,
  PhotoTypeEnum,
  PhotoTypeLabels,
  StatusKYCEnum
} from './kyc.interface';
import { remoteStreamVideoGlobal } from '../../utils/webSocket/videoRequest';
import GalleryModal from './modal/modal-gallery';
import ChooseItem from './components/choose-item';
import ScreenshotModal from './modal/modal-screenshot';
import ToggleBtn from '../form/inbox/toggle-field';
import { mainToggleList } from '../../static/kyc-config';
import { useTakePictureKYC } from '../../api/hooks/kyc/useTakePictureKYC';
import { AllowTakePicEnum } from '../../static/communication-type';
import { PayloadKYCParam } from '../../api/param.interface';
import { isNullOrUndefined } from '../../utils/isNullUndefined';
import { ConversationStatusEnum, MessageEventEnum } from '../../static/message-event';
import { CurrentUserObjectInterface } from '../../shared/types/user.interface';
import { useCallStore } from '../../store/call-state';
import { useSubmitKYC } from '../../api/hooks/kyc/useSubmitKYC';
import { CaptureScreeshoot, SelectInstructuctionFrame } from '../../api/kyc';
import RejectReasonAgent from '../form/inbox/reject-reason-agent';
import { getValueByKeyCaseInsensitive } from '../../utils/common';
import { getClientConfig } from '../../utils/getClientConfig';
import SelectImage from './components/select-image';
import { useConversationDocument } from '../../api/hooks/kyc/useConversationDocument';

function ManualKYC({ setPage }: ManualKYCInterface) {
  const [form] = Form.useForm();
  const [allowTakePicForm] = Form.useForm();

  const { mutate } = useTakePictureKYC();
  const { mutateAsync: submitKYC, isError } = useSubmitKYC();
  const {
    setIsFinishChooseKYC,
    resetAndSetKYCState,
    metadata,
    setIsManualKYCSubmited,
    ktpPreviews,
    selfiePreviews
  } = useSelectedCustomerStore();
  const {
    isFinishKYC,
    setIsFinishKYC,
    setIsApprovedKYC,
    isApprovedKYC,
    chooseKTP,
    setChooseKTP,
    chooseSelfie,
    setChooseSelfie,
    setFileKTP,
    setFileSelfie,
    isLoadingUploadKYC,
    setIsLoadingUploadKYC
  } = useSelectedCustomerStore();
  const { isNewAttachments, setIsNewAttachment, selectedCall } = useChatListStore();
  const { isManualKYCMode, photoType, setPhotoType, setZoomFullscreen } = useCallStore();

  const [openScreenshot, setOpenScreenshot] = useState<boolean>(false);
  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const [img, setImg] = useState<HTMLImageElement | null>(null);

  const { data: documents, refetch: fetchDocuments } = useConversationDocument(
    selectedCall?.conversationId || undefined
  );

  const canvasRefP: React.RefObject<HTMLCanvasElement> = useRef<HTMLCanvasElement>(null);
  const canvasRefL: React.RefObject<HTMLCanvasElement> = useRef<HTMLCanvasElement>(null);

  const rejectReason = Form.useWatch('reject-reason', form);
  const isSubmitted = localStorage.getItem('isKYCSubmitted') === 'true';
  const isNextBtnDisabled =
    isManualKYCMode &&
    (!isFinishKYC ||
      (!isApprovedKYC && isNullOrUndefined(rejectReason?.value)) ||
      isLoadingUploadKYC ||
      selectedCall?.event === MessageEventEnum.INACTIVE ||
      isSubmitted);

  const isBtnDisabled = selectedCall?.event === MessageEventEnum.INACTIVE;
  const shouldDisabledAccRejectKYC = !chooseKTP || !chooseSelfie || isBtnDisabled;
  const conversationId = selectedCall?.conversationId || '';

  let remoteStreamVideo: any = document.getElementById('remote-video');
  var snapshot: any = document.getElementById('snapshot');

  const { hideSelfieButton } = getClientConfig();

  const toggleOptions = useMemo(
    () => {
      const isNotAllowTakePicture = allowTakePicForm.getFieldValue('allowTakePicture');
      return mainToggleList(allowTakePicForm, () => {
        mutate({
          conversationId: conversationId,
          actionType: isNotAllowTakePicture
            ? AllowTakePicEnum.SWITCH_CAMERA_OFF
            : AllowTakePicEnum.SWITCH_CAMERA_ON
        });
      });
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [allowTakePicForm, Form.useWatch('allowTakePicture', allowTakePicForm)]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  useEffect(() => {
    takeScreenshot();
    // eslint-disable-next-line
  }, [openScreenshot]);
  useEffect(() => {
    resetAndSetKYCState();
    selectedCall?.conversationStatus !== ConversationStatusEnum.OPEN &&
      selectedCall?.conversationStatus !== ConversationStatusEnum.IN_PROGRESS &&
      selectedCall?.conversationStatus &&
      setIsManualKYCSubmited(true);
    // eslint-disable-next-line
  }, [selectedCall?.conversationId]);

  useEffect(() => {
    if (chooseKTP != null && chooseSelfie != null && isFinishKYC) setIsFinishChooseKYC(true);
    // eslint-disable-next-line
  }, [chooseKTP, chooseSelfie, isFinishKYC]);

  useEffect(() => {
    if (photoType) {
      SelectInstructuctionFrame(selectedCall?.conversationId, photoType);
    } else {
      SelectInstructuctionFrame(selectedCall?.conversationId, 'NULL');
    }

    // eslint-disable-next-line
  }, [photoType]);

  /**
   * Takes a screenshot of the remote stream video.
   *
   * @param remoteStreamVideo - The video element containing the remote stream.
   * @param openScreenshot - Whether to take a screenshot or not.
   */
  const takeScreenshot = () => {
    if (null != remoteStreamVideoGlobal && openScreenshot) {
      // Calculate the aspect ratio of the image
      const aspectRatio = remoteStreamVideo.videoWidth / remoteStreamVideo.videoHeight;

      // Set the desired width and height of the displayed image
      const maxWidth = 552;
      const maxHeight = 320;

      let displayWidth = maxWidth;
      let displayHeight = maxWidth / aspectRatio;

      // Check if the calculated height exceeds the maxHeight
      // If so, adjust the dimensions to fit within maxHeight
      if (displayHeight > maxHeight) {
        displayHeight = maxHeight;
        displayWidth = maxHeight * aspectRatio;
      }

      let canvasRef = displayWidth > displayHeight ? canvasRefL?.current : canvasRefP?.current;
      let ctx = canvasRef?.getContext('2d');
      let img: HTMLImageElement = new Image();
      let copyImage = new Image();
      ctx?.drawImage(remoteStreamVideo, 0, 0, canvasRef!?.width, canvasRef!?.height);
      img.src = canvasRef?.toDataURL('image/png') || '';

      copyImage.src = img.src;
      copyImage.width = displayWidth;
      copyImage.height = displayHeight;

      setImg(copyImage);
      snapshot.innerHTML = '';
      snapshot.appendChild(copyImage);
    }
  };

  /**
   * Handle captures a screenshot.
   */
  const handleOkScreenshot = async () => {
    CaptureScreeshoot(selectedCall?.conversationId || '');
    setOpenScreenshot(false);
    setPhotoType(null);
  };

  /**
   * Handles canceling the screenshot.
   */
  const handleCancelScreenshot = () => {
    setOpenScreenshot(false);
  };

  /**
   * Handles the ok action for the gallery.
   *
   * @param conversationId The conversation ID.
   * @returns A Promise that resolves to void.
   */
  const handleOkeGallery = async () => {
    CaptureScreeshoot(selectedCall?.conversationId || '');
    setOpenGallery(false);
    setIsNewAttachment(false);
  };

  /**
   * Handles the cancel action for the gallery.
   *
   * @param setOpenGallery - A function to set the state of openGallery.
   * @param setIsNewAttachment - A function to set the state of isNewAttachment.
   * @returns void
   */
  const handleCancelGallery = () => {
    setOpenGallery(false);
    setIsNewAttachment(false);
  };

  /**
   * Uploads KYC documents and updates the state accordingly.
   *
   * @param approved - Indicates whether the KYC is approved or not.
   * @returns Promise<void>
   */
  const handleUploadDocument = async (approved: boolean) => {
    setIsFinishKYC(true);
    setIsApprovedKYC(approved);
    setIsLoadingUploadKYC(false);
  };

  /**
   * Generates a classname based on the approval type.
   *
   * @param approvalType - Boolean indicating the approval type.
   * @param chooseKTP - Boolean indicating whether KTP is chosen.
   * @param chooseSelfie - Boolean indicating whether selfie is chosen.
   * @param isFinishKYC - Boolean indicating whether KYC is finished.
   * @param isApprovedKYC - Boolean indicating whether KYC is approved.
   * @returns The generated classname.
   */
  const generateClassName = (approvalType: boolean) => {
    let classname: string =
      'group manual-kyc-btn w-full text-sm lg:text-base font-semibold hover:border-white ';

    if (!chooseKTP || !chooseSelfie) return classname + 'text-gray-300 border-gray-300';
    if (isFinishKYC && isApprovedKYC && approvalType)
      return classname + 'text-emerald-light-green border-emerald-light-green';
    if (isFinishKYC && !isApprovedKYC && !approvalType)
      return classname + 'text-red-500 border-red-500';

    return classname + 'text-black border-black';
  };

  const submitKYCVerdict = async () => {
    const user: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');

    const payload: PayloadKYCParam = {
      data: {
        decision: isApprovedKYC ? StatusKYCEnum.COMPLETED : StatusKYCEnum.FAILED,
        rejectionReason: {
          reason: isApprovedKYC ? '' : rejectReason?.label,
          subReason: '',
          reasonId: rejectReason?.value
        },
        governmentId:
          getValueByKeyCaseInsensitive(metadata, 'NIK') ||
          getValueByKeyCaseInsensitive(selectedCall?.metadata || {}, 'NIK'),
        document: {
          idCardPhotoID: chooseKTP!?.documentId,
          selfiePhotoId: chooseSelfie!?.documentId
        }
      },
      conversationId: selectedCall!?.conversationId,
      accountId: user!?.account!?.accountId
    };

    localStorage.setItem('isKYCSubmitted', 'true');
    setIsManualKYCSubmited(true);

    await submitKYC(payload)
      .catch((error) => {
        localStorage.removeItem('isKYCSubmitted');
        // eslint-disable-next-line no-console
        console.log(error);
        setPage!(KYCPageEnum.KYC);
      })
      .finally(() => {
        resetAndSetKYCState();
      });
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line
  }, [isNewAttachments]);

  useEffect(() => {
    if (photoType) {
      setZoomFullscreen(false);
    }
    //eslint-disable-next-line
  }, [photoType]);

  const isButtonHidden = (input: string) => {
    return hideSelfieButton && input === 'SELFIE';
  };

  return (
    <>
      <GalleryModal
        open={openGallery}
        handleOk={handleOkeGallery}
        handleCancel={handleCancelGallery}
        documents={documents?.documents || []}
        setIsNewAttachment={setIsNewAttachment}
      />
      <ScreenshotModal
        open={openScreenshot}
        handleOk={handleOkScreenshot}
        handleCancel={handleCancelScreenshot}
        canvasRefP={canvasRefP}
        canvasRefL={canvasRefL}
        chooseKTP={chooseKTP}
        chooseSelfie={chooseSelfie}
        setFileKTP={setFileKTP}
        setFileSelfie={setFileSelfie}
        img={img}
      />
      <div>
        <p className={`manual-kyc-p text-sm xl:text-base`}>
          Pastikan data yang tertera sudah sesuai dan lengkapi dengan foto verifikasi.
        </p>
      </div>
      <div
        className={`my-2 mb-4 flex w-full flex-col gap-2 rounded-xl border border-solid border-black p-6 max-xl:p-4`}>
        <>
          <p className={`manual-kyc-p mt-2 flex-nowrap text-sm font-bold xl:text-lg`}>
            Tampilkan Petunjuk Foto:
          </p>
          <div className={`flex flex-col items-center justify-center gap-3`}>
            <div className="flex w-full gap-2 overflow-y-hidden py-2 pb-4">
              {Object.values(PhotoTypeEnum).map((type) => (
                <Button
                  key={type}
                  disabled={isBtnDisabled}
                  onClick={() => setPhotoType(type)}
                  className={`${(photoType === type || !photoType) && 'border-2'} ${
                    isButtonHidden(type) ? 'hidden' : ''
                  } border-black-90 text-base text-black-90 max-xl:py-2 max-xl:text-xs`}>
                  {PhotoTypeLabels[type]}
                </Button>
              ))}
            </div>
            <p className={`manual-kyc-p self-start text-sm xl:text-base`}>
              Petunjuk akan langsung ditampilkan di layar pelanggan.
            </p>
            <ChooseItem
              disabled={isBtnDisabled}
              onOpenSS={() => setOpenScreenshot(true)}
              type={ChoosItemEnum.SCREENSHOT}
              label="Ambil Screenshot"
            />
            <ChooseItem
              disabled={isBtnDisabled}
              onOpenGallery={() => setOpenGallery(true)}
              type={ChoosItemEnum.GALLERY}
              label="Pilih Foto"
            />
            <Form
              className="w-full"
              name="allowTakePicture"
              layout="vertical"
              form={allowTakePicForm}
              autoComplete="off"
              requiredMark={false}
              initialValues={{
                allowTakePicture: false
              }}
              size="large">
              {selectedCall?.inboxConfig?.mediaCallConfiguration?.showCapturePhoto && (
                <Row align="middle" justify="space-between">
                  {ToggleBtn(toggleOptions)}
                </Row>
              )}
            </Form>
            {isNewAttachments && (
              <div className="flex w-full flex-row items-center justify-center gap-2 rounded-lg bg-emerald-light-green px-2 py-1 text-white">
                <ExclamationCircleFilled />
                <p className="m-0 p-0">
                  Foto berhasil diambil. Hasil ada di menu “+ Tambah Preview”.
                </p>
              </div>
            )}
          </div>
        </>
        {isError && (
          <Alert
            showIcon
            icon={
              <ExclamationCircleOutlined
                className="text-red-danger"
                style={{ fontSize: '1.5rem' }}
              />
            }
            description="Data Gagal Dikirim. Silahkan Kirim Kembali"
            type="error"
            className="mt-2 rounded-xl bg-red-danger-light text-left text-base text-red-danger sm:text-sm"
            banner={true}
          />
        )}
      </div>

      <SelectImage
        title="Foto KTP"
        hideCompare={isBtnDisabled}
        imageUrls={ktpPreviews.map((item) => item.documentUrl!)}
        currentPreviews={ktpPreviews}
        previewType={ChooseTypeEnum.KTP}
        selectedDoc={chooseKTP}
        setOpenGallery={setOpenGallery}
        onChoose={setChooseKTP}
      />

      <SelectImage
        title="Foto KTP + selfie"
        hideCompare={isBtnDisabled}
        imageUrls={selfiePreviews.map((item) => item.documentUrl!)}
        currentPreviews={selfiePreviews}
        previewType={ChooseTypeEnum.SELFIE}
        selectedDoc={chooseSelfie}
        setOpenGallery={setOpenGallery}
        onChoose={setChooseSelfie}
      />

      <div className="flex flex-row items-center justify-center gap-3 xl:mt-2">
        <Button
          type="primary"
          htmlType="button"
          onClick={() => handleUploadDocument(true)}
          disabled={shouldDisabledAccRejectKYC!}
          className={`${generateClassName(true)} hover:bg-green-emerald-light max-xl:p-2`}
          shape="round">
          <div className={`flex w-full flex-row items-center justify-center gap-2`}>
            <CheckOutlined className="group-hover:text-white" />
            <p className="m-0 group-hover:text-white max-xl:text-xs">Setuju</p>
          </div>
        </Button>
        <Button
          danger
          type="primary"
          htmlType="button"
          onClick={() => handleUploadDocument(false)}
          disabled={shouldDisabledAccRejectKYC!}
          className={`${generateClassName(false)} w-full hover:bg-red-500 max-xl:p-2`}
          shape="round">
          <div className={`flex w-full flex-row items-center justify-center gap-2`}>
            <CloseOutlined className="group-hover:text-white" />
            <p className="m-0 group-hover:text-white max-xl:text-xs">Tolak</p>
          </div>
        </Button>
      </div>
      {!isApprovedKYC && isFinishKYC && (
        <Form
          className="customer-form my-4 w-full"
          name="reject-reason"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
          size="large">
          <RejectReasonAgent form={form} />
        </Form>
      )}
      <Button
        type="primary"
        htmlType="submit"
        className={`${!isNextBtnDisabled ? `bg-bold-green` : `bg-grey-50`} mt-4 max-xl:!p-2`}
        disabled={isNextBtnDisabled!}
        onClick={submitKYCVerdict}>
        <div className="flex flex-row items-center gap-2">
          <p className="text-white">Kirim</p>
        </div>
      </Button>
    </>
  );
}

export default ManualKYC;
