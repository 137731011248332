import React from 'react';
import { Image, List } from 'antd';
import { useWorkspaceDocuments } from '../../../api/hooks/workspace/useWorkspaceDocuments';
import { useChatListStore } from '../../../store/chat-list';
import { downloadFileFromUrl } from '../../../config/file-api-handler';
import { ArrowDownOutlined } from '@ant-design/icons';
import { getIconUrl } from '../../../utils/getIconUrl';

const PartialFiles = () => {
  const { selectedCall } = useChatListStore();

  const { data: documentDetail, isLoading: loadingDocuments } = useWorkspaceDocuments(
    selectedCall?.conversationId || ''
  );

  const handlePreviewDocument = (url: string) => window.open(url, '_blank');
  const handleDownloadDocument = (url: string, fileName: string) =>
    downloadFileFromUrl(url, fileName);

  return (
    <List
      className={`[&>.ant-spin-nested-loading>.ant-spin-container>.ant-row]:gap-y-2 ${
        documentDetail?.documents?.length > 4 &&
        '[&>.ant-spin-nested-loading>.ant-spin-container]:min-h-[244px]'
      }`}
      pagination={
        documentDetail?.documents?.length > 0 && {
          position: 'bottom',
          align: 'center',
          pageSize: 4
        }
      }
      dataSource={documentDetail?.documents}
      grid={{ xs: 1, lg: 2, xl: 2, xxl: 3, column: 1 }}
      loading={loadingDocuments}
      locale={{ emptyText: 'Belum ada berkas' }}
      renderItem={(item: any) => (
        <div className="w-[95%] space-y-2 rounded-xl border border-solid border-grey-50 p-2">
          <div className="flex items-center justify-between">
            <div className="flex-1 truncate font-semibold">{item?.documentName}</div>
            <div className="flex w-8 justify-end">
              <div
                className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border border-solid border-gray-500 p-2"
                onClick={() => handleDownloadDocument(item?.documentUrl, item?.documentName)}>
                <ArrowDownOutlined className="text-[10px] text-gray-500" />
              </div>
            </div>
          </div>
          <div
            className="overflow-hidden rounded-xl"
            onClick={() => handlePreviewDocument(item?.documentUrl)}>
            {/* Preview */}
            <Image
              className="h-[64px] w-full cursor-pointer object-cover xl:h-[80px]"
              width={'100%'}
              preview={false}
              src={item?.documentUrl}
              alt={item?.documentName}
              fallback={getIconUrl('img-fallback.png')}
            />
          </div>
        </div>
      )}
    />
  );
};

export default PartialFiles;
