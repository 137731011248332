import { Divider, Flex } from 'antd';
import { ChooseItemInterface } from './kyc.interface';
import { getIconUrl } from '../../../utils/getIconUrl';
import { Button, Paragraph } from '@squantumengine/horizon';

function ChooseItem(item: ChooseItemInterface) {
  const { onOpenSS, onOpenGallery, label, icon, isAllowTakePicture } = item;

  return (
    <Flex
      vertical
      justify="center"
      align="center"
      className="box-border rounded-xl border border-dashed border-neutral-400 py-3">
      <Flex gap={16} align="center">
        <img src={getIconUrl(icon)} alt="kyc-1" />
        <Paragraph>{label}</Paragraph>
      </Flex>
      <Divider className="my-4" />
      <Flex align="center" justify="center">
        <Button className="h-auto text-black" variant="text" onClick={onOpenSS}>
          <Paragraph weight="bold">Ambil screenshot</Paragraph>
        </Button>
        {isAllowTakePicture && (
          <>
            <Divider type="vertical">Atau</Divider>
            <Button className="h-auto text-black" variant="text" onClick={onOpenGallery}>
              <Paragraph weight="bold">Pilih foto upload</Paragraph>
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default ChooseItem;
