import { create } from 'zustand';
import { ChatListInterface } from './chat-list.interface';
import { ConversationInterface } from '../components/card/chat-message/chat-message.interface';
import { useConversationListStore } from './conversation-list-state';
import { ConversationType } from './conversation-list-state.interface';
import { getConversationListKey } from './conversation-list.helper';

const handleIncrementUnreadMessage =
  (conversationId: string, inboxId: string) => (state: ChatListInterface) => {
    let updates: Partial<ChatListInterface> = {};
    if (conversationId === state.selectedCall?.conversationId) {
      return {};
    }

    // Logic for handleIncrementUnreadMessage
    if (conversationId !== state.selectedCall?.conversationId) {
      const unreadMessages = { ...state.unreadMessages };
      unreadMessages[conversationId] = unreadMessages[conversationId]
        ? unreadMessages[conversationId] + 1
        : 1;
      updates.unreadMessages = unreadMessages;
    }

    // Logic for handleIncrementUnreadMessageInboxId
    const unreadMessagesInboxId = { ...state.unreadMessagesInboxId };
    unreadMessagesInboxId[inboxId] = unreadMessagesInboxId[inboxId]
      ? unreadMessagesInboxId[inboxId] + 1
      : 1;
    updates.unreadMessagesInboxId = unreadMessagesInboxId;

    return updates;
  };

const handleClearUnreadMessage =
  (conversationId: string, inboxId: string) => (state: ChatListInterface) => {
    const unreadMessages = { ...state.unreadMessages };
    const unreadMessagesInboxId = { ...state.unreadMessagesInboxId };
    unreadMessagesInboxId[inboxId] =
      unreadMessagesInboxId[inboxId] - (unreadMessages[conversationId] || 0);
    delete unreadMessages[conversationId];

    return { unreadMessages, unreadMessagesInboxId };
  };

const handleSelectCall = (selectedCall: ConversationInterface | null) => {
  if (selectedCall) {
    localStorage.setItem('selectedCall', JSON.stringify(selectedCall));

    const targetList = getConversationListKey(selectedCall.event);
    const list = useConversationListStore.getState()[targetList] as ConversationInterface[];
    const index = list.findIndex((conversation) => conversation.id === selectedCall.id);

    if (index > -1) {
      list[index] = selectedCall;
      useConversationListStore
        .getState()
        .setConversationList(selectedCall.event as ConversationType, list);
    }
  }

  return { selectedCall, selectedOfflineChat: null };
};

export const useChatListStore = create<ChatListInterface>((set) => ({
  allChatList: [],
  offlineChatList: [],
  selectedCall: null,
  selectedOfflineChat: null,
  unreadMessages: {},
  unreadMessagesInboxId: {},
  isUserTyping: false,
  isNewAttachments: false,
  isReadyToClose: false,
  conversationChatList: [],
  setIsReadyToClose: (isReadyToClose) => set({ isReadyToClose }),
  appendChatList: (newChatList) =>
    set((state) => ({ allChatList: [...state.allChatList, ...newChatList] })),
  setAllChatList: (allChatList) => set({ allChatList }),
  setOfflineChatList: (offlineChatList) => set({ offlineChatList }),
  appendOfflineChatList: (newOfflineChatList) =>
    set((state) => ({ offlineChatList: [...state.offlineChatList, ...newOfflineChatList] })),
  clearChatListStore: () => set({ allChatList: [], selectedCall: null }),
  setSelectedCall: (selectedCall) => set(handleSelectCall(selectedCall)),
  setSelectedOfflineChat: (selectedOfflineChat) => set({ selectedOfflineChat, selectedCall: null }),
  incrementUnreadMessage: (conversationId: string, inboxId: string) =>
    set(handleIncrementUnreadMessage(conversationId, inboxId)),
  clearUnreadMessages: (conversationId: string, inboxId: string) =>
    set(handleClearUnreadMessage(conversationId, inboxId)),
  setIsUserTyping: (isUserTyping) => set({ isUserTyping }),
  resetSelectedCall: () => {
    localStorage.removeItem('selectedCall');
    set({ selectedCall: null, conversationChatList: [] });
  },
  setIsNewAttachment: (isNewAttachments) => set({ isNewAttachments }),
  setConversationChatList: (conversationChatList) => set({ conversationChatList }),
}));
