import { CheckCircleFilled } from '@ant-design/icons';
import { Flex, Modal } from 'antd';
import { useGetUsers } from '../../../../api/hooks/sqeid/useSQEID';
import { Button, Paragraph, Title } from '@squantumengine/horizon';
import { UserConfirmationModalInterface } from '../users.interface';

const UserConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsModalEditOpen,
  setSelectedData
}: UserConfirmationModalInterface) => {
  const { data: invUserData } = useGetUsers();

  return (
    <Modal
      title={
        <Flex justify="start" align="center" gap="middle">
          <CheckCircleFilled className="text-3xl text-blue-500" />
          <Title className="m-0" level={5}>
            Undangan Berhasil Dikirim
          </Title>
        </Flex>
      }
      closable={false}
      open={isModalOpen}
      footer={
        <Flex justify="end" gap="middle">
          <Button className="w-48" variant="secondary" onClick={() => setIsModalOpen(false)}>
            Lengkapi Nanti
          </Button>
          <Button
            className="w-48"
            onClick={() => {
              setIsModalOpen(false);
              setIsModalEditOpen(true);
              setSelectedData(invUserData!?.data[0]);
            }}>
            Lengkapi Sekarang
          </Button>
        </Flex>
      }>
      <Paragraph>
        Langkah selanjutnya adalah melengkapi profil user. Silakan lengkapi sekarang atau bisa
        lengkapi nanti
      </Paragraph>
    </Modal>
  );
};

export default UserConfirmationModal;
