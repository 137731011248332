import { useEffect, useRef, useState } from 'react';

import ChatMessage from '../../chat-message';
import ChatFooter from '../../chat-footer';
import ConfirmationModal from '../../../modal/confirmation-modal';

import { ChatBoxInterface } from './chatbox.interface';
import { ChatMessageInterface } from '../../chat-message/chat-message.interface';

import { postWidgetMessage } from '../../../../config/send-messages';
import { sendTypingStatus } from '../../../../config/send-messages/typing';
import { messageHistory } from '../../../../config/message-history';

import ChatSlashIcon from '../../../../assets/icons/chat-slash';
import { CloseConversationEnum, MessageEventEnum } from '../../../../static/message-event';
import { useCallStore } from '../../../../store/call-state';
import { getIconUrl } from '../../../../utils/getIconUrl';
import InfiniteScroll from 'react-infinite-scroller';
import VideoCall from '../../../video-call';
import { useUserStatusStore } from '../../../../store/user-state';
import { RoleEnum } from '../../../../static/role';
import RedoIcon from '../../../../assets/icons/redo';
import { useChatListStore } from '../../../../store/chat-list';
import angleDoubleLeft from '../../../../assets/icons/angle-double-left.svg';
import {
  CommunicationTypeEnum,
  defaultCommunicationTypeEnum
} from '../../../../static/communication-type';
import VideoRequest, { leaveMeeting } from '../../../../utils/webSocket/videoRequest';
import { Spin } from 'antd';
import { getSelectedCallJSON, setSelectedCallJSON } from '../../../../utils/user-json';
import CloseSessionButton from '../../close-session';
import { CurrentUserObjectInterface } from '../../../../shared/types/user.interface';
import { DeleteCall } from '../../../../api/call';
import { meetingRoomId } from '../../../../utils/webSocket';
import { useGreetingMessage } from '../../../../hooks/useGreetingMessage';
import { useTeamStore } from '../../../../store/team-state';
import CustomerDisconnectBar from '../../../video-call/CustomerDisconnectBar';

function ChatBox({
  onClose,
  withoutVideo,
  allowRefresh,
  showHeading = true,
  showCustomerAction = true
}: ChatBoxInterface) {
  const [chat, setChat] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showChatAttention, setShowChatAttention] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    setShowVideo,
    setFullScreen,
    fullScreen,
    showCustomerOfflineAlert,
    showVideo,
    attentionChatIds,
    showChat,
    setShowChat,
    setMeetingRoomId,
    websocketConnected,
    isVideoRequested,
    setIsVideoRequested,
    isManualKYCMode,
    setIsVoiceCall,
    setShowCameraButton
  } = useCallStore();
  const { userRole } = useUserStatusStore();
  const { selectedCall, allChatList, isUserTyping, conversationChatList, setConversationChatList } =
    useChatListStore();
  const { selectedInbox } = useTeamStore();

  useGreetingMessage(conversationChatList, setConversationChatList, selectedInbox);

  const filteredChatlist = allChatList.filter(
    (chat) => chat.conversationId === selectedCall?.conversationId
  );

  const mergedArray = [...conversationChatList, ...filteredChatlist];

  const filteredArray = mergedArray.reduce((acc: ChatMessageInterface[], obj) => {
    const existingObj = acc.find((item) => item.id === obj.id);
    if (!existingObj) {
      acc.push(obj);
    }
    return acc;
  }, []);

  const conversationId = selectedCall?.conversationId || '';
  const isAllowTxtMsg = selectedCall?.inboxConfig?.allowTextMessage;

  const scrollToBottom = () => {
    const chatContainer = scrolldata?.current;
    setTimeout(() => {
      chatContainer?.scrollBy({ top: chatContainer.scrollHeight, left: 0, behavior: 'smooth' });
    }, 210);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setChat(e.target.value);
  };

  const handleEndCall = () => {
    if (selectedCall?.communicationType === CommunicationTypeEnum.VIDEO) leaveMeeting();
    setShowModal(false);
    setShowVideo(false);
    onClose && onClose(CloseConversationEnum.PRECLOSE);
  };

  const addChat = (e: any) => {
    e.preventDefault();
    if (chat) {
      postWidgetMessage(chat, [], 'TEXT', conversationId);
      setChat('');
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (chat && !isTyping) {
      sendTypingStatus(true, conversationId);
      setIsTyping(true);
    } else if (!chat && isTyping) {
      sendTypingStatus(false, conversationId);
      setIsTyping(false);
    }
  }, [chat, conversationId, isTyping]);

  useEffect(() => {
    setShowChatAttention(!!attentionChatIds.length && userRole === RoleEnum.AGENT);
  }, [attentionChatIds.length, userRole]);

  useEffect(() => {
    setHasMore(true);

    if (conversationId) {
      if (userRole !== RoleEnum.SUPERVISOR) setConversationChatList(filteredChatlist);
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, userRole]);

  const scrolldata = useRef<HTMLDivElement>(null);

  const transformMessage = (data: any): ChatMessageInterface =>
    ({
      id: data?.messageId,
      message: data?.content,
      time: data?.timestamp || Date.now(),
      isUser: data?.senderType === MessageEventEnum.CONTACT,
      documents: data?.documents,
      type: data?.documents
        ? MessageEventEnum.FILE
        : data?.messageType === MessageEventEnum.MEDIA_CALL
        ? MessageEventEnum.CALL
        : MessageEventEnum.TEXT,
      name: data?.sender?.name,
      conversationId: conversationId
    } as ChatMessageInterface);

  const loadPreviousMessages = async () => {
    let getLastId = conversationChatList[0]?.id;
    if (getLastId === 'greeting') getLastId = conversationChatList[1]?.id;

    const res = await messageHistory(conversationId, getLastId);
    const transformedMessageList = res?.messageList?.map(transformMessage);
    if (res?.messageList?.length === 0) {
      setHasMore(false);
    }
    if (transformedMessageList) {
      setConversationChatList([...transformedMessageList.reverse(), ...conversationChatList]);
    }
  };

  const loadNewMessages = async () => {
    setLoading(true);
    scrollToBottom();
    const newMessages: ChatMessageInterface[] = [];
    let lastId = undefined;
    let fetchMore = true;
    let res;

    // fetches new messages until an existing chat is found
    do {
      res = await messageHistory(conversationId, lastId);

      for (let i = 0; i < res.messageList.length; i++) {
        const currentId = res.messageList[i].messageId;
        const firstId = filteredArray[0]?.id;
        const lastId = filteredArray[filteredArray.length - 1]?.id;

        if (currentId === firstId || currentId === lastId) {
          fetchMore = false;
          break;
        } else {
          newMessages.push(transformMessage(res.messageList[i]));
        }
      }

      lastId = res.messageList[res.messageList.length - 1]?.messageId;
    } while (res.messageList.length && fetchMore);

    newMessages.length > 0 &&
      setConversationChatList([...conversationChatList, ...newMessages.reverse()]);
    setLoading(false);
  };

  const isActiveCall = selectedCall?.event === MessageEventEnum.ACTIVE;
  const shouldShowFooter =
    userRole === RoleEnum.AGENT &&
    (selectedCall?.event === MessageEventEnum.SESSION_CLOSED || isActiveCall) &&
    (isAllowTxtMsg || !isManualKYCMode);

  const showAkhiriSesiButton = userRole === RoleEnum.AGENT && selectedCall;

  const showAgentName = (
    currentAgentName: string | undefined,
    isUser: boolean,
    prevAgentName?: string,
    message?: string
  ) => {
    const isSameSender = prevAgentName === currentAgentName;
    return !isUser && message && !isSameSender;
  };

  const handleAnswerCall = () => {
    const defaultCommunicationType =
      selectedCall?.inboxConfig?.mediaCallConfiguration.defaultCommunicationType;

    const isAudioOnly = defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO;
    const isAudioVideo = defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO_VIDEO;
    const isVoiceCall = isAudioOnly || isAudioVideo;
    // switched condition when customer request from audio call to video call
    const isSwitched =
      selectedCall?.metadata?.communicationType === defaultCommunicationTypeEnum.VIDEO;
    VideoRequest({ audio: true, video: !isVoiceCall });
    setIsVoiceCall((isAudioVideo || isAudioOnly) && !isSwitched);
    setShowCameraButton(isSwitched || !isVoiceCall);
    setShowVideo(true);
  };

  let firstRender = useRef(true);
  useEffect(() => {
    const hasClickEndCall = sessionStorage.getItem(`end-call-${meetingRoomId}`);
    if (
      firstRender.current &&
      localStorage.meetingRoomId &&
      websocketConnected &&
      !withoutVideo &&
      !isVideoRequested
    ) {
      if (!hasClickEndCall && localStorage.meetingRoomId === selectedCall?.meetingRoomId) {
        setIsVideoRequested(true);
        handleAnswerCall();
        setMeetingRoomId(localStorage.meetingRoomId);
        firstRender.current = false;
      }
    }
    // eslint-disable-next-line
  }, [websocketConnected, isVideoRequested, selectedCall?.meetingRoomId]);

  useEffect(() => {
    const hasClickEndCall = sessionStorage.getItem(`end-call-${meetingRoomId}`);
    const isAbleToRefresh = localStorage.meetingRoomId;
    if (isAbleToRefresh && isManualKYCMode && !hasClickEndCall) setFullScreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManualKYCMode]);

  const currentUser: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');
  const accountId = currentUser.account?.accountId || '';
  const addNotesHandler = () => {
    if (selectedCall && selectedCall.event === MessageEventEnum.ACTIVE) {
      if (selectedCall?.communicationType === CommunicationTypeEnum.VIDEO) leaveMeeting();
      setShowVideo(false);
      setFullScreen(false);
      const selectedCallJSON = getSelectedCallJSON();
      selectedCallJSON.event = MessageEventEnum.SESSION_CLOSED;
      setShowChat(false);
      setSelectedCallJSON(JSON.stringify(selectedCallJSON));
      localStorage.removeItem('meetingRoomId');
      localStorage.removeItem('isSwicthed');

      onClose && onClose(CloseConversationEnum.PRECLOSE);
      // if no video interaction happened, we also skipped DeleteCall
      if (accountId && localStorage.videoMessageId)
        DeleteCall(accountId, conversationId, localStorage.videoMessageId);
    }
  };

  const endTicketHandler = () => {
    if (selectedCall && selectedCall.event === MessageEventEnum.SESSION_CLOSED) {
      if (selectedCall?.communicationType === CommunicationTypeEnum.VIDEO) leaveMeeting();
      setShowVideo(false);

      const selectedCallJSON = getSelectedCallJSON();
      selectedCallJSON.event = MessageEventEnum.INACTIVE;
      setSelectedCallJSON(JSON.stringify(selectedCallJSON));

      onClose && onClose(CloseConversationEnum.CLOSE);
      setShowVideo(false);
      setFullScreen(false);
      setShowChat(false);
      setMeetingRoomId('');
      localStorage.removeItem('meetingRoomId');
      localStorage.removeItem('isSwicthed');
      localStorage.removeItem('isKYCSubmitted');
    }
  };

  const RenderChatMessages = () => {
    return filteredArray
      ?.filter((chat) => {
        if (!isAllowTxtMsg) return chat?.type !== MessageEventEnum.TEXT;
        else return chat;
      })
      .map((chat, index) => (
        <div key={index}>
          {showAgentName(chat.name, chat.isUser, filteredArray[index - 1]?.name, chat.message) && (
            <div className="ml-[72px] px-4 py-1">{chat.name}</div>
          )}
          <ChatMessage {...chat} conversationId={conversationId} />
        </div>
      ));
  };

  return (
    <>
      {showHeading && (
        <div className="flex items-center justify-between">
          <p className="m-0 text-3xl font-semibold">Live Chat</p>
          {allowRefresh && selectedCall && (
            <div
              className="cursor-pointer"
              role="button"
              aria-label="redo"
              onClick={loadNewMessages}>
              <RedoIcon />
            </div>
          )}
          {showChat && (
            <button
              onClick={() => setShowChat(false)}
              className="cursor-pointer border-none bg-transparent ">
              <img className="rotate-180" src={angleDoubleLeft} alt="" />
            </button>
          )}
        </div>
      )}
      <div
        className={`relative flex ${
          showChat ? 'h-max' : 'h-full'
        } min-h-[400px] flex-col overflow-hidden rounded-lg border border-solid border-grey-80 bg-white-background`}>
        {showCustomerAction && (
          <div className="z-50 flex w-full flex-row items-center justify-start px-6 py-4 shadow-[0px_4px_16px_rgba(0,0,0,0.15)]">
            <div className="flex w-full flex-col items-start justify-start">
              <div className="flex w-full items-center justify-between text-lg font-semibold">
                <div className="flex flex-1 items-center justify-center gap-6 overflow-hidden">
                  <div
                    className={`h-2 w-2 min-w-[8px] truncate rounded-full ${
                      selectedCall?.event === MessageEventEnum.ACTIVE
                        ? 'bg-bold-green'
                        : 'bg-chat-gray'
                    } `}
                  />
                  <div className="w-full truncate">{selectedCall?.name || 'Pelanggan'}</div>
                </div>
                {showAkhiriSesiButton && (
                  <div className="ml-2">
                    <CloseSessionButton
                      onClickAddNotes={addNotesHandler}
                      onClickEndTicket={endTicketHandler}
                    />
                  </div>
                )}
                <ConfirmationModal
                  modalState={showModal}
                  setModalState={setShowModal}
                  handleConfirm={handleEndCall}
                />
              </div>
              {isUserTyping && isActiveCall && <div className="pl-8">typing...</div>}
            </div>
          </div>
        )}
        {showVideo && showCustomerOfflineAlert && !fullScreen && <CustomerDisconnectBar />}
        {showVideo && !withoutVideo && <VideoCall />}
        {showChatAttention && (
          <div className="flex items-center bg-orange-danger px-6 py-5 text-base font-semibold text-white">
            <img src={getIconUrl('alert-rounded.svg')} alt="alert-icon" className="mr-4" />
            Segera jawab customer
          </div>
        )}
        {selectedCall ? (
          <div
            id="chat-container"
            ref={scrolldata}
            className={`${showChat && 'max-h-[500px]'} ${showVideo ? 'h-max' : 'h-full '} ${
              shouldShowFooter ? 'mb-44' : 'mb-0'
            } relative  w-full overflow-y-auto pt-4`}>
            <InfiniteScroll
              loadMore={loadPreviousMessages}
              initialLoad
              isReverse
              useWindow={false}
              pageStart={0}
              hasMore={hasMore}
              getScrollParent={() => scrolldata.current}>
              {RenderChatMessages()}
            </InfiniteScroll>
            {loading && (
              <div className="flex justify-center">
                <Spin spinning={loading} />
              </div>
            )}
          </div>
        ) : (
          <div className="flex w-full flex-1 flex-col items-center justify-center text-grey-100">
            <div className="scale-[200%]">
              <ChatSlashIcon />
            </div>
            <p className="text-lg font-semibold">Belum ada chat</p>
          </div>
        )}
        {shouldShowFooter && (
          <div className="absolute bottom-0 w-full">
            <ChatFooter chat={chat} addChat={addChat} handleChange={handleChange} />
          </div>
        )}
      </div>
    </>
  );
}

export default ChatBox;
