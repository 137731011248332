export interface HTMLAudioElementWithSinkId extends HTMLAudioElement {
  setSinkId: (sinkId: string) => Promise<void>;
}

export let videoNotificationPlayer: Awaited<ReturnType<typeof createAudioPlayer>> | null = null;
export let chatNotificationPlayer: Awaited<ReturnType<typeof createAudioPlayer>> | null = null;

export const createAudioPlayer = async (url: string, loop: boolean = false) => {
  const audioOutputs = await getAllAudioOutputs();
  const audioElements = createAudioElements(audioOutputs, url);
  addDeviceEventListener();

  return {
    audio: audioElements,
    isPaused: false,

    play: function () {
      this.audio.forEach((audioEl) => {
        audioEl.loop = loop;
        audioEl.play();
      });
      this.isPaused = false;
    },

    stop: async function () {
      this.audio.forEach((audioEl) => {
        audioEl.currentTime = 0;
        audioEl.pause();
      });
      this.isPaused = true;
    }
  };
};

export const getAllAudioOutputs = async () => {
  const devices = await navigator?.mediaDevices.enumerateDevices();
  const audioOutputs = devices?.filter((device) => device.kind === 'audiooutput');

  return audioOutputs;
};

export const createAudioElements = (audioOutputs: MediaDeviceInfo[], audioSrc: string) => {
  // Use a Set to track unique device IDs
  const uniqueDeviceIds: string[] = [];
  const uniqueGroupIds: string[] = [];
  const audioElements: HTMLAudioElementWithSinkId[] = [];

  audioOutputs.forEach(async (device) => {
    if (!uniqueDeviceIds.includes(device.deviceId) && !uniqueGroupIds.includes(device.groupId)) {
      uniqueDeviceIds.push(device.deviceId); // Add the device ID to the Set
      uniqueGroupIds.push(device.groupId); // Add the group ID to the Set

      const audioElement = new Audio(audioSrc) as HTMLAudioElementWithSinkId;
      audioElements.push(audioElement);
    }
  });

  uniqueDeviceIds.forEach(async (deviceId, i) => {
    await audioElements[i].setSinkId(deviceId);
  });

  return audioElements;
};

export const playVideoNotification = async () => {
  if (videoNotificationPlayer && !videoNotificationPlayer.isPaused) return;

  await stopVideoNotification();

  videoNotificationPlayer = await createAudioPlayer(
    require('../assets/sounds/video-call.mp3'),
    true
  );

  videoNotificationPlayer.play();
};

export const stopVideoNotification = async () => {
  if (!videoNotificationPlayer) return;
  await videoNotificationPlayer.stop();
  videoNotificationPlayer = null;
  removeDeviceEventListener();
};

export const playChatNotification = async () => {
  if (!chatNotificationPlayer) {
    chatNotificationPlayer = await createAudioPlayer(
      require('../assets/sounds/chat-notification.mp3'),
      false
    );
  }
  chatNotificationPlayer.play();
};

export const removeDeviceEventListener = () => {
  navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
};

export const addDeviceEventListener = () => {
  removeDeviceEventListener();
  navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
};

export const handleDeviceChange = async () => {
  if (videoNotificationPlayer) {
    await stopVideoNotification();
    playVideoNotification();
  }
};
