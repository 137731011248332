import axiosInstance from '../config/axios';
import {
  AgentListItemInterface,
  AgentMaxChatInterface,
  AgentCapacityInterface
} from '../shared/types/agent.interface';
import { InboxMemberInterface } from '../shared/types/inbox.interface';
import {
  CreateUserParam,
  SetUserPasswordParam,
  OnBoardAgentParam,
  UpdateUserParam,
  AssignAgentToConversationParam,
  UnassignAgentFromConversationParam
} from './param.interface';
import { CommunicationTypeEnum } from '../static/communication-type';
import { getUserAccessTokenObj, getUserJSON } from '../utils/user-json';
import { AgentInfoInterface } from '../shared/types/user.interface';
import { ResponseErrorEnum, ResultErrorEnum } from '../static/error-response';
import { SSOLoginResponse } from '../shared/types/auth.interface';
import { UserResponse } from '../components/card/user/user.interface';
import { UpdateUserPayloadParam } from '../pages/v2/users/users.interface';

export const fetchUserList: (
  accountId: string,
  role: string[]
) => Promise<AgentListItemInterface[]> = async (accountId, role) => {
  const response = await axiosInstance.get(`agent/account/${accountId}/agents?role=${role.join()}`);
  return response.data;
};

export const fetchInboxUserList: (
  accountId: string,
  inboxId: string
) => Promise<InboxMemberInterface> = async (accountId, inboxId) => {
  try {
    const response = await axiosInstance.get(`account/${accountId}/inbox/${inboxId}/members`);
    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const validateCCAgent: (agentIds: string[]) => Promise<string[]> = async (agentIds) => {
  const response = await axiosInstance.get(`account/ccAgent/check?agentIds=${agentIds.join()}`);
  return response.data;
};

export const createUser: (data: CreateUserParam) => Promise<any> = async (data) => {
  const response = await axiosInstance.post(`agent`, { ...data });
  return response?.data;
};

export const getUserDetail: (agentId: string) => Promise<any> = async (data) => {
  const response = await axiosInstance.get(`agent?agentId=${data}`);
  return response.data;
};

export const getAgentMaxChat: (agentId: string) => Promise<AgentMaxChatInterface> = async (
  data
) => {
  const response = await axiosInstance.get(`account/agent/${data}/capacity`);
  return response.data;
};

export const deleteUser: (agentId: string) => Promise<any> = async (data) => {
  const response = await axiosInstance.delete(`agent/${data}`);
  return response?.data;
};

export const updateUser: ({
  agentId,
  firstName,
  lastName,
  phone
}: UpdateUserParam) => Promise<any> = async ({ agentId, firstName, lastName, phone }) => {
  await axiosInstance.put(`agent/${agentId}`, { firstName, lastName, phone });
  const response = await axiosInstance.get(`agent?agentId=${agentId}`);
  return response.data;
};

export const setUserPassword: (data: SetUserPasswordParam) => Promise<any> = async (data) => {
  const response = await axiosInstance.post(`agent/password`, { ...data });
  return response.data;
};

export const onBoardAgent: (data: OnBoardAgentParam) => Promise<any> = async (data) => {
  const response = await axiosInstance.post(`account/${data.accountId}/agent`, {
    agentId: data.agentId,
    agentCapacity: {
      maxChatCustomers: data.maxChat,
      maxCallCustomers: 1
    }
  });
  return response.data;
};

export const updateAgent: (data: OnBoardAgentParam) => Promise<AgentCapacityInterface> = async (
  data
) => {
  const response = await axiosInstance.put(`account/${data.accountId}/agent`, {
    agentId: data.agentId,
    agentCapacity: {
      maxChatCustomers: data.maxChat,
      maxCallCustomers: 1
    }
  });

  return response.data;
};

export const assignAgentToConversation: (
  data: AssignAgentToConversationParam
) => Promise<void> = async (data) => {
  const type = data.communicationType || CommunicationTypeEnum.CHAT;
  await axiosInstance.post(
    `account/${data.accountId}/conversation/${data.conversationId}/agent/assignment?agentId=${data.agentId}&communicationType=${type}`
  );
};

export const unassignAgentFromConversation: (
  data: UnassignAgentFromConversationParam
) => Promise<void> = async (data) => {
  await axiosInstance.post(
    `account/${data.accountId}/conversation/${data.conversationId}/agent/un-assignment`
  );
};

export const GetProfilePictureUrl: (accountId: string, agentId: string) => Promise<string> = async (
  accountId: string,
  agentId: string
) => {
  try {
    const response = await axiosInstance.get(`account/${accountId}/agent/${agentId}/photo`);
    return response.data.url;
  } catch (_) {
    return 'agent_profile_photo/null';
  }
};

export const UploadProfilePicture: (files: any) => Promise<any> = async (files: any) => {
  const user = getUserJSON();

  const formData = new FormData();
  formData.append('files', files);

  try {
    const response = await axiosInstance.post(
      `agent/${user.agentId}/account/${user.account?.accountId}/photo/profile`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateAgentStatus: (accountId: string, status: string) => Promise<any> = async (
  accountId: string,
  status: string
) => {
  try {
    const response = await axiosInstance.put(`account/${accountId}/agent/status?online=${status}`);
    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const receiveAgentStatus: (accountId: string) => Promise<AgentInfoInterface> = async (
  accountId: string
) => {
  const response = await axiosInstance.get(`account/${accountId}/agent/info`);
  return response?.data;
};

export const logoutNATIVE: () => Promise<void> = async () => {
  await axiosInstance.post(`agent/logout`);
};

export const logoutSIMASID: () => Promise<void> = async () => {
  const { refresh_token } = getUserAccessTokenObj();

  await axiosInstance.post('agent/logout', null, {
    headers: {
      'Refresh-token': `jwt ${refresh_token}`
    }
  });
};

export const loginSIMASID: (
  authCode: string,
  codeVerifier: string,
  username: string
) => Promise<SSOLoginResponse> = async (
  authCode: string,
  codeVerifier: string,
  username: string
) => {
  try {
    const response = await axiosInstance.post(`agent/login`, {
      grantType: 'authorization_code',
      authCode,
      codeVerifier,
      username,
      callbackUrl: `${process.env.REACT_APP_SQEID_REDIRECT_URI}`
    });
    return {
      error: false,
      data: response?.data
    };
  } catch (error: any) {
    const { message: errMsg } = error?.response?.data;
    let errResponseMsg = '';

    switch (errMsg) {
      case ResponseErrorEnum.AGENT_NOT_FOUND:
        errResponseMsg = ResultErrorEnum.AGENT_NOT_FOUND;
        break;
      case ResponseErrorEnum.WRONG_PASSWORD:
        errResponseMsg = ResultErrorEnum.WRONG_PASSWORD;
        break;
      case ResponseErrorEnum.AGENT_NEED_UPDATED:
        errResponseMsg = ResultErrorEnum.AGENT_NEED_UPDATED;
        break;
      default:
        errResponseMsg = ResultErrorEnum.GENERAL_ERROR;
        break;
    }

    return {
      error: true,
      errorMessage: errResponseMsg
    };
  }
};

export const generateTokenSQEID: (payload: any) => Promise<string> = async (payload) => {
  try {
    const response = await axiosInstance.post(`user/token/invite`, payload);

    return response?.data;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
};

export interface GetUserParams {
  pageIndex?: number;
  pageSize?: number;
}

export const getUserSQEID = async ({ pageIndex = 1, pageSize = 10 }: GetUserParams = {}): Promise<{
  data: UserResponse[];
  size: number;
  pageNo: number;
}> => {
  try {
    const response = await axiosInstance.get(`users?page-no=${pageIndex}&size=${pageSize}`);
    return response?.data;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
};

export const updateUserProfile: (
  payload: UpdateUserPayloadParam
) => Promise<UserResponse[]> = async (payload) => {
  try {
    const response = await axiosInstance.post(`profile`, payload);
    return response?.data?.data;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
};

export const delUserSQEID: (email: string) => Promise<any> = async (email) => {
  try {
    const response = await axiosInstance.post(`user/delete`, { email });
    return response?.data;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
};

export const addInboxMember: ({
  accountId,
  inboxId,
  agentId
}: {
  accountId: string;
  inboxId: string;
  agentId: string;
}) => Promise<any[]> = async ({ accountId, inboxId, agentId }) => {
  const response = await axiosInstance.post(
    `account/${accountId}/inbox/${inboxId}/member?agentId=${agentId}`
  );
  return response.data;
};

export const removeInboxMember: ({
  accountId,
  inboxId,
  agentId
}: {
  accountId: string;
  inboxId: string;
  agentId: string;
}) => Promise<any[]> = async ({ accountId, inboxId, agentId }) => {
  const response = await axiosInstance.delete(
    `account/${accountId}/inbox/${inboxId}/member?agentId=${agentId}`
  );
  return response.data;
};
