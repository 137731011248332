import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout } from 'antd';

import { useAuth } from '../hooks/useAuth';

import Header from '../components/header';
import Sidebar from '../components/sidebar';
import { PageLocation } from '../types/enums/page';
// import { HIDE_FEATURE_PROD } from '../config/app';
import { useSidebarStore } from '../store/new/sidebar';
import HeaderV2 from '../components/v2/header';
import WarningIddleAutoLogout from '../components/modal/warning-logout';
import { LocalStorageKey } from '../static/local-storage';
import { undevelopedV2Routes } from '../static/routes';

export const ProtectedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const v2 = location.pathname.includes('v2');
  const { isExpanded } = useSidebarStore();

  const { accessToken } = useAuth();
  const { isErrorPage, layoutCls } = useMemo(() => {
    const isErrorPage = location.pathname.includes(PageLocation.ERROR);
    const mlV2 = isExpanded ? 'ml-[268px]' : 'ml-[84px]';
    const ml = v2 ? mlV2 : 'ml-52';
    const bgColor = v2 ? 'bg-[#F9FBFF]' : 'bg-white';
    return {
      isErrorPage,
      layoutCls: isErrorPage ? 'h-full' : `${bgColor} ${ml} mt-16 h-full`
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isExpanded]);

  useEffect(() => {
    const clientCode = localStorage.getItem(LocalStorageKey.SQECC_CLIENT_CODE);
    let isUndevelopedRoute = false;
    undevelopedV2Routes.forEach((route) => {
      if (location.pathname.includes(route)) isUndevelopedRoute = true;
    });
    /**
     * If the client is techconnect
     * and the it's not a v2 route
     * and we already develop the route.
     * We navigate to v2 route.
     */
    if (clientCode === 'techconnect' && !v2 && !isUndevelopedRoute) {
      /**
       * Since v1 and v2 team route have different base route
       * We hardcode the url
       */
      if (location.pathname.includes('/team')) {
        navigate(`/v2/dashboard/team/list`);
      } else {
        navigate(`/v2${location.pathname}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  // if (v2 && HIDE_FEATURE_PROD) {
  //   return <Navigate to="/dashboard" />;
  // }

  return (
    <Layout className="bg-white">
      <WarningIddleAutoLogout />
      {v2 ? <HeaderV2 /> : <Header />}
      {!isErrorPage && (
        <Layout className="flex-none">
          <Sidebar />
        </Layout>
      )}
      <Layout className={layoutCls}>
        <Outlet />
      </Layout>
    </Layout>
  );
};
