/* eslint-disable no-console */
import { create } from 'zustand';
import { ConnectedStoreInterface, ConnectionStateEnum } from './connection-state.interface';

export const useConnectionStore = create<ConnectedStoreInterface>((set) => ({
  connectionState: ConnectionStateEnum.GOOD,
  setConnectionState: (value: ConnectionStateEnum) =>
    set(() => ({
      connectionState: value
    })),
  connectionStateCustomer: ConnectionStateEnum.GOOD,
  setConnectionStateCustomer: (value: ConnectionStateEnum) =>
    set(() => ({
      connectionStateCustomer: value
    }))
}));
