import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useConversationListStore } from '../../store/conversation-list-state';

export const useRefresh = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (isRefreshing) {
      setTimeout(() => {
        setIsRefreshing(false);
      }, 100);
    }
  }, [isRefreshing]);

  return {
    isRefreshing,
    refresh: () => setIsRefreshing(true)
  };
};

export const useLastUpdate = () => {
  const defaultTime = dayjs().toISOString();

  const [lastUpdateTime, setLastUpdateTime] = useState(defaultTime);
  const {
    assignedConversationList,
    activeConversationList,
    closedConversationList,
    preClosureConversationList,
    lastReplyConversation
  } = useConversationListStore();

  const onSetLastUpdateTime = () => {
    const currentTime = dayjs().toISOString();
    setLastUpdateTime(currentTime);
  };

  useEffect(() => {
    onSetLastUpdateTime();
  }, [
    assignedConversationList,
    activeConversationList,
    closedConversationList,
    preClosureConversationList,
    lastReplyConversation
  ]);

  return {
    lastUpdateTime: dayjs(lastUpdateTime).format('HH:mm')
  };
};
