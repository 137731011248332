import { useSelectedCustomerStore } from '../../../store/customer-state';
import { DocumentInterface } from '../../form/workspace/workspace.interface';
import { ChooseTypeEnum } from '../kyc.interface';
import CardCompareImage from './card-compare-image';
import ChooseResult from './choose-result';

interface SelectImageProps {
  title: string;
  currentPreviews: DocumentInterface[];
  imageUrls: string[];
  previewType: ChooseTypeEnum;
  selectedDoc: DocumentInterface | null;
  hideCompare: boolean;
  setOpenGallery: (bool: boolean) => void;
  onChoose: (doc: DocumentInterface | null) => void;
}

const SelectImage = ({
  title,
  currentPreviews,
  imageUrls,
  previewType,
  selectedDoc,
  hideCompare,
  setOpenGallery,
  onChoose
}: SelectImageProps) => {
  const { setIsAddPreview, setAddPreviewType, setIsChangePreview, setChangePreviewIndex } =
    useSelectedCustomerStore();

  return (
    <>
      {!selectedDoc && !hideCompare && (
        <div className="mb-4">
          <CardCompareImage
            title={title}
            previewImages={imageUrls}
            handleAddPreview={() => {
              setIsAddPreview(true);
              setAddPreviewType(previewType);
              setOpenGallery(true);
            }}
            onChange={(index) => {
              setOpenGallery(true);
              setAddPreviewType(previewType);
              setIsChangePreview(true);
              setChangePreviewIndex(index);
            }}
            onChoose={(index) => {
              onChoose(currentPreviews[index]);
            }}
            showAddButton={currentPreviews.length < 2}
          />
        </div>
      )}

      {selectedDoc && (
        <div className="mb-4 rounded-xl border border-solid border-black p-4">
          <ChooseResult
            data={selectedDoc}
            setData={() => onChoose(null)}
            name={previewType}
            type={previewType}
          />
        </div>
      )}
    </>
  );
};

export default SelectImage;
