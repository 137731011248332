import { LegacyRef } from 'react';
import { DocumentInterface } from '../../form/workspace/workspace.interface';
import { SelectOption } from '@squantumengine/horizon';

export interface ModeTypeInterface {
  KTP: boolean;
  SELFIE: boolean;
}

export enum ModeTypeEnum {
  KTP = 'KTP',
  SELFIE = 'Selfie'
}

export interface GalleryModalInterface {
  open: boolean;
  confirmLoading: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  documents: DocumentInterface[];
  setIsNewAttachment: (isNewAttachments: boolean) => void;
  type: string;
}

export interface ScreenshotModalInterface {
  open: boolean;
  confirmLoading: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  canvasRefP: LegacyRef<HTMLCanvasElement> | undefined;
  canvasRefL: LegacyRef<HTMLCanvasElement> | undefined;
  setFileKTP: (chooseKTP: File | null) => void;
  setFileSelfie: (chooseSefile: File | null) => void;
  chooseKTP: DocumentInterface | null;
  chooseSelfie: DocumentInterface | null;
  img: HTMLImageElement | null;
  type: string;
}

export interface ChooseItemInterface {
  onOpenSS: () => void;
  onOpenGallery: () => void;
  label: string;
  icon: string;
  isAllowTakePicture: boolean;
}

export interface ChooseResultInterface {
  data: DocumentInterface;
  name: string;
  isKYCSubmitted: boolean;
  setData?: () => void;
}

export enum ChoosItemEnum {
  GALLERY = 'Gallery',
  SCREENSHOT = 'Screenshot'
}

export enum ChooseTypeEnum {
  KTP = 'KTP',
  SELFIE = 'Selfie'
}

export enum StatusKYCEnum {
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  FACE_UMATCH = 'FACE_UNMATCH'
}

export enum StatusKYCResultEnum {
  FAILED = 'Ditolak',
  APPROVED = 'Disetujui'
}

export enum PhotoTypeEnum {
  NORMAL = 'NORMAL',
  KTP = 'KTP',
  SELFIE = 'SELFIE',
  BOTH = 'BOTH'
}

export const PhotoTypeLabels: Record<PhotoTypeEnum, string> = {
  [PhotoTypeEnum.NORMAL]: 'NORMAL',
  [PhotoTypeEnum.KTP]: 'KTP',
  [PhotoTypeEnum.SELFIE]: 'Selfie',
  [PhotoTypeEnum.BOTH]: 'KTP & Selfie'
};

export interface KYCVerdictResponse {
  email: string;
  fullName: string;
  ktpObjectName: string;
  mobile: string;
  refUserId: string;
  selfieObjectName: string;
  status: string;
  userId: string;
  reason: string;
  manualKyc: boolean;
}

export enum KYCPageEnum {
  KYC = 0,
  SUMMARY = 1,
  SUBMITTED = 2
}

export interface KYCAlertInterface {
  text: string;
  type: 'ERROR' | 'SUCCESS';
}

export interface ManualKYCInterface {
  setPage?: React.Dispatch<React.SetStateAction<number>>;
}

export interface KYCPageInterface {
  defaultPage?: number;
}

export interface ManualKYCFunnelInterface {
  queue: number; // status dropped and resolved
  inProgress: number; // resolved with or without verdict and reference id
  resolved: number; // resolved with verdict
  approved: number; // resolved with verdict and approved
  metadata: MetaDataKYFunnelInterface;
  averageWaitTime: number; // unix_time_in_second
  averageVerificationTime: number; // unix_time_in_second
}

export interface TopRejectReasonInterface {
  data: Array<{
    rejectReason: string;
    total: number;
  }>;
  totalConversation: number;
  metadata: {
    start: string;
    end: string;
    responseTime: number;
    timestamp: string;
  };
}
export interface MetaDataKYFunnelInterface {
  start: number;
  end: number;
  responseTime: number;
  timestamp: number;
}

export interface FunnelHover {
  type: 'avg-wait' | 'avg-verif' | 'approve';
  isShown: boolean;
  totalDrop: number;
}

export interface RejectReasonKYCInterface {
  setRejectReason: React.Dispatch<React.SetStateAction<SelectOption | undefined>>;
}

export enum ImageOrientationEnum {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait'
}
