import { ChooseResultInterface } from '../kyc.interface';
import { useChatListStore } from '../../../store/chat-list';
import { ConversationStatusEnum } from '../../../static/message-event';
import { Icon, Paragraph } from '@squantumengine/horizon';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import ModalExpandPhoto from '../modal/modal-expand-photo';

function ChooseResult(item: ChooseResultInterface) {
  const { setData, data, name } = item;
  const { selectedCall } = useChatListStore();
  const isPrecloseOrResolved =
    selectedCall?.conversationStatus === ConversationStatusEnum.PRE_CLOSURE ||
    selectedCall?.conversationStatus === ConversationStatusEnum.RESOLVED;

  const { setIsExpandPhoto, setAddPreviewType, setExpandPhotoUrl } = useSelectedCustomerStore();

  const onCancel = () => {
    setData();
  };

  const expandPhoto = () => {
    setIsExpandPhoto(true);
    setAddPreviewType(item.type);
    setExpandPhotoUrl(data.documentUrl || '');
  };

  return (
    <>
      <ModalExpandPhoto />
      {data && (
        <div className="flex w-full flex-col items-start gap-2">
          <Paragraph size="l" weight="semibold">
            Foto {name}
          </Paragraph>
          <div className="flex w-full flex-row items-center gap-3 overflow-hidden rounded-[12px] bg-grey-20 pr-4 text-center">
            {!data?.documentUrl && isPrecloseOrResolved ? (
              <p className="ml-4"> No Image</p>
            ) : (
              <>
                <img
                  src={data?.documentUrl}
                  alt="choose-item"
                  className="h-[48px] w-[48px] object-cover"
                />

                <Paragraph className="truncate">{data?.documentName}</Paragraph>

                <div className="ml-auto flex shrink-0 flex-row items-center gap-2">
                  <div className="flex cursor-pointer flex-row items-center" onClick={expandPhoto}>
                    <Icon name="expand-wide" />
                  </div>
                  {!isPrecloseOrResolved && (
                    <div className="flex cursor-pointer flex-row items-center" onClick={onCancel}>
                      <Icon name="close-large" />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ChooseResult;
