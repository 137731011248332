import { Button } from '@squantumengine/horizon';
import { Form, Input, Tooltip, Modal } from 'antd';
import { PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { getImageUrl } from '../../../../../utils/getIconUrl';
import useTeamCreateEditStore from '../../store';
import { useQuery } from 'react-query';
import { getSQEMPTemplates, getSQMPPrecodings } from '../../../../../services/whatsapp';
import {
  TemplateMessage,
  WhatsappAttribute,
  WhatsappCommunicationTypeEnum,
  WhatsAppMessage
} from '../../../../../shared/types/whatsapp.interface';
import { useAuthStore } from '../../../../../store/new/auth';
import { useAccountInfo } from '../../../../../services/account/hooks';
import { buildQueryString } from '../../../../../utils/string';
import TemplateItem from './template-item';
import FormItemWhatsApp from './form-item-whastapp';
import { sqempWAURL } from '../../../../../static/sqeMp';

type Props = {
  getFormRef: (form: any) => void;
};

export default function FormWhatsAppWidget(props: Props) {
  const accountId = useAuthStore((state) => state.account.accountId);
  const { data: accountData } = useAccountInfo(accountId);
  const organizationId = accountData?.organizationId;
  const store = useTeamCreateEditStore();
  const [templateList, setTemplateList] = useState<TemplateMessage[]>([]);
  const [selectedAutoMessage, setSelectedAutoMessage] = useState<Record<string, WhatsAppMessage>>(
    {}
  );

  const { data: SQEMPTemplates } = useQuery(
    ['getSQEMPTemplates', accountId],
    () => getSQEMPTemplates(accountId),
    { enabled: !!accountId }
  );

  const { data: SQEMPPrecodings } = useQuery(
    ['getSQEMPPrecodings', accountId],
    () => getSQMPPrecodings(accountId),
    { enabled: !!accountId }
  );

  const templateOptions =
    SQEMPTemplates?.data?.map((template: WhatsAppMessage) => {
      return {
        value: template.id,
        label: template.name
      };
    }) || [];

  const handleSelectTemplate = (idx: number, campaignId: string) => {
    const campaign = SQEMPTemplates?.data?.find((item: WhatsAppMessage) => item.id === campaignId);
    setTemplateList((prev) => {
      const newList = [...prev];
      newList[idx] = { ...newList[idx], campaign };
      return newList;
    });
    store.setFormWhatsapp({
      ...store.formWhatsapp,
      templateMessages: [
        ...store.formWhatsapp.templateMessages.map((template, i) => {
          if (i === idx) return { ...template, campaign };
          return template;
        })
      ]
    });
  };
  const handleSelectPrecodingTemplate = (idx: number, precoding: string, value: string) => {
    setTemplateList((prev) => {
      const newList = [...prev];
      const currentTemplate = { ...newList[idx] };
      if (currentTemplate?.campaign) {
        currentTemplate.campaign.dataAttributes = {
          ...currentTemplate.campaign.dataAttributes,
          [precoding]: value
        };
        newList[idx] = currentTemplate;
      }
      return newList;
    });
    store.setFormWhatsapp({
      ...store.formWhatsapp,
      templateMessages: [
        ...store.formWhatsapp.templateMessages.map((template, i) => {
          if (i === idx) {
            const campaign = template?.campaign;
            if (campaign)
              campaign.dataAttributes = { ...campaign.dataAttributes, [precoding]: value };
            return { ...template, campaign };
          }
          return template;
        })
      ]
    });
  };

  const handleSelectAutoMessage = (name: string, value: string) => {
    const template = SQEMPTemplates?.data?.find(
      (template: WhatsAppMessage) => template.id === value
    );
    setSelectedAutoMessage((prevState) => ({ ...prevState, [name]: template }));
  };

  const handleSelectPrecodingAutoMessage = (
    messageKey: string,
    precoding: string,
    value: string
  ) => {
    const formWhatsapp = useTeamCreateEditStore.getState().formWhatsapp;

    setSelectedAutoMessage((prevState) => {
      return {
        ...prevState,
        [messageKey]: {
          ...prevState[messageKey],
          dataAttributes: {
            ...prevState[messageKey]?.dataAttributes,
            [precoding]: value
          }
        }
      };
    });
    const newCampaignConfiguration: WhatsappAttribute[] = [
      ...(formWhatsapp.campaignConfigurations || [])
    ];
    const campaign = newCampaignConfiguration.find(
      (campaignConfiguration) => campaignConfiguration.campaignType === messageKey
    );
    if (campaign) {
      if (!campaign.dataAttributes) campaign.dataAttributes = {};
      campaign.dataAttributes[precoding] = value;
      store.setFormWhatsapp({
        ...formWhatsapp,
        campaignConfigurations: newCampaignConfiguration
      });
    }
  };

  const [form] = Form.useForm();

  const formItemWhatsappWidget = [
    {
      name: WhatsappCommunicationTypeEnum.CONNECTED_TO_AGENT,
      type: 'textarea',
      label: 'Request Terhubung dengan Agen',
      placeholder: 'Masukkan pesan untuk terhubung dengan agen',
      tooltip: (
        <div className="px-2 pb-2">
          <p>Pesan ini akan muncul pada saat pelanggan memulai chat di WhatsApp.</p>
          <img
            className="w-full"
            src={getImageUrl('tooltip-wa-request-connect.png')}
            alt="request-connect"
          />
        </div>
      ),
      checked: false
    },
    {
      name: WhatsappCommunicationTypeEnum.WAITING_ROOM,
      type: 'textarea',
      label: 'Ruang Tunggu',
      placeholder: 'Masukkan pesan ruang tunggu',
      tooltip: (
        <div className="px-2 pb-2">
          <p>Pesan ini akan muncul ketika pelanggan sedang menunggu untuk terhubung dengan agen.</p>
          <img
            className="w-full"
            src={getImageUrl('tooltip-wa-waiting-room.png')}
            alt="waiting-room"
          />
        </div>
      )
    },
    {
      name: WhatsappCommunicationTypeEnum.GREETING_MESSAGE,
      type: 'greeting-message',
      label: 'Kata Sambutan',
      placeholder: 'Masukkan kata sambutan',
      tooltip: (
        <div className="px-2 pb-2">
          <p>Pesan ini akan muncul pada saat pelanggan terhubung dengan agen.</p>
          <img
            className="w-full"
            src={getImageUrl('tooltip-wa-greeting-message.png')}
            alt="greeting-message"
          />
        </div>
      ),
      checked: false
    },
    {
      name: WhatsappCommunicationTypeEnum.LONG_QUEUE,
      type: 'textarea',
      label: 'Antrean Panjang',
      placeholder: 'Masukkan pesan antrean panjang',
      tooltip: (
        <div className="px-2 pb-2">
          <p>Pesan ini akan muncul pada saat percakapan belum dijawab oleh agen.</p>
          <img className="w-full" src={getImageUrl('tooltip-wa-long-queue.png')} alt="long-queue" />
        </div>
      )
    },
    {
      name: WhatsappCommunicationTypeEnum.OUTSIDE_WORKING_HOUR,
      type: 'textarea',
      label: 'Di Luar Jam Kerja',
      placeholder: 'Masukkan pesan di luar jam kerja',
      tooltip: (
        <div className="px-2 pb-2">
          <p>Pesan ini akan muncul saat pelanggan menghubungi di luar jam operasional.</p>
          <img
            className="w-full"
            src={getImageUrl('tooltip-wa-outside-working-hour.png')}
            alt="outside-working-hour"
          />
        </div>
      )
    },
    {
      name: WhatsappCommunicationTypeEnum.CLOSING_REMARKS,
      type: 'textarea',
      label: 'Kata Penutup',
      placeholder: 'Masukkan kata penutup',
      tooltip: (
        <div className="px-2 pb-2">
          <p>Pesan ini akan muncul pada saat agen mengakhiri sesi dengan pelanggan.</p>
          <img
            className="w-full"
            src={getImageUrl('tooltip-wa-close-message.png')}
            alt="closing-message"
          />
        </div>
      )
    }
  ];

  const handleAddSQEMPTemplate = () => {
    if (!organizationId) return;
    const uuid = store.setDraft();
    const params = {
      orId: organizationId,
      redirectUrl: `${window.location.origin}${window.location.pathname}?draftId=${uuid}`,
      email: useAuthStore.getState().user.email,
      sc: 'cc'
    };
    const url = `${sqempWAURL}?${buildQueryString(params)}`;
    window.open(url, '_self');
  };

  const handleEditSQEMPTemplate = (template: any) => {
    if (!organizationId || !template?.id) return;
    const uuid = store.setDraft();
    const params = {
      orId: organizationId,
      redirectUrl: encodeURI(`${window.location.origin}${window.location.pathname}?draftId=${uuid}`)
    };
    const url = `${sqempWAURL}/${template.id}?${buildQueryString(params)}`;
    window.open(url, '_self');
  };

  const handleAddTemplateMessage = async () => {
    let templateTitle = '';

    const modal = Modal.confirm({
      closable: true,
      icon: null,
      title: <span className="font-semibold">Pesan Template Baru</span>,
      content: (
        <Input
          className="w-full"
          placeholder="Judul Pesan Template"
          onChange={(e) => (templateTitle = e.target.value)}
        />
      ),

      className:
        '[&>.ant-modal-content>.ant-modal-body>.ant-modal-confirm-body-wrapper>.ant-modal-confirm-body>.ant-modal-confirm-paragraph]:!max-w-full',
      style: { padding: 0 },
      bodyStyle: { padding: 0 },
      footer: (
        <div className="mt-3 flex justify-end space-x-2">
          <Button variant="secondary" size="sm" onClick={() => modal.destroy()}>
            Batalkan
          </Button>
          <Button
            size="sm"
            onClick={() => {
              if (!templateTitle) return;
              const template = { name: templateTitle };
              setTemplateList((prev) => [...prev, template]);
              store.setFormWhatsapp({
                ...store.formWhatsapp,
                templateMessages: [
                  ...store.formWhatsapp.templateMessages,
                  {
                    name: templateTitle
                  }
                ]
              });
              modal.destroy();
            }}>
            Simpan
          </Button>
        </div>
      )
    });
  };

  const handleDeleteTemplate = (index: number) => {
    setTemplateList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelecteTemplateChange = (name: string, value: string) => {
    handleSelectAutoMessage(name, value);
    const formWhatsapp = useTeamCreateEditStore.getState().formWhatsapp;
    const newCampaignConfiguration: WhatsappAttribute[] = [
      ...(formWhatsapp.campaignConfigurations || [])
    ];
    const campaign = newCampaignConfiguration.find(
      (campaignConfiguration) => campaignConfiguration.campaignType === name
    );
    if (campaign) {
      campaign.campaignId = value;
      campaign.campaignType = name as WhatsappCommunicationTypeEnum;
      campaign.dataAttributes = selectedAutoMessage[name]?.dataAttributes;
    } else {
      newCampaignConfiguration.push({
        campaignId: value,
        campaignType: name as WhatsappCommunicationTypeEnum,
        dataAttributes: selectedAutoMessage[name]?.dataAttributes
      });
    }
    store.setFormWhatsapp({
      ...formWhatsapp,
      campaignConfigurations: newCampaignConfiguration
    });
  };

  useEffect(() => {
    props.getFormRef(form);
  }, [props.getFormRef]);

  const isRendered = useRef(false);
  useEffect(() => {
    if (isRendered.current || !SQEMPTemplates) return;
    isRendered.current = true;
    props?.getFormRef(form);
    const campaignConfigurations = store.formWhatsapp.campaignConfigurations;
    const initialFormValues = campaignConfigurations.reduce((acc, campaign: WhatsappAttribute) => {
      acc[campaign.campaignType] = campaign.campaignId;
      if (campaign.dataAttributes) {
        Object.keys(campaign.dataAttributes).forEach((key) => {
          acc[`${campaign.campaignType}-${key}`] = campaign.dataAttributes[key];
        });
      }
      return acc;
    }, {} as any);

    const initialSelectedAutoMessage = campaignConfigurations.reduce(
      (acc, campaign: WhatsappAttribute) => {
        acc[campaign.campaignType] = SQEMPTemplates?.data?.find(
          (template: WhatsAppMessage) => template.id === campaign.campaignId
        );
        return acc;
      },
      {} as any
    );
    setSelectedAutoMessage(initialSelectedAutoMessage);

    const templateMessages = store.formWhatsapp.templateMessages;
    setTemplateList(templateMessages);
    const templateFormValues = templateMessages.reduce((acc, template, idx) => {
      acc[`template-${idx}`] = template?.campaign?.id;
      if (template?.campaign?.dataAttributes) {
        Object.keys(template?.campaign?.dataAttributes).forEach((key) => {
          acc[`template-${idx}-${key}`] = template?.campaign?.dataAttributes[key];
        });
      }
      return acc;
    }, {} as any);
    form.setFieldsValue({ ...initialFormValues, ...templateFormValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SQEMPTemplates]);

  return (
    <div className="px-4 pb-4">
      <Form form={form} layout="horizontal" className="space-y-4">
        <div className="space-y-4 rounded-xl bg-white p-6 shadow-md">
          <h3 className="m-0 flex items-center space-x-2 text-lg font-semibold">
            <span>Pesan Template </span>
            <Tooltip
              title="Pesan layanan yang dapat dikirim oleh agen ke pelanggan untuk memulai percakapan WhatsApp."
              className="ml-1 fill-black">
              <QuestionCircleOutlined />
            </Tooltip>
          </h3>
          <div className="space-y-4">
            <div className="space-y-4">
              {templateList.map(({ name, campaign }, idx) => (
                <TemplateItem
                  name={name}
                  campaign={campaign}
                  index={idx}
                  form={form}
                  SQEMPPrecodings={SQEMPPrecodings}
                  templateOptions={templateOptions}
                  handleAddSQEMPTemplate={handleAddSQEMPTemplate}
                  handleEditSQEMPTemplate={handleEditSQEMPTemplate}
                  handleDeleteTemplate={handleDeleteTemplate}
                  handleSelectPrecodingTemplate={(precodingKey: string, value: string) =>
                    handleSelectPrecodingTemplate(idx, precodingKey, value)
                  }
                  handleSelectTemplate={(value: string) => handleSelectTemplate(idx, value)}
                />
              ))}
            </div>
            <div className="border-grey-300 flex items-center justify-center rounded-lg border border-dotted border-grey-50 py-2">
              <Button
                variant="text"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  handleAddTemplateMessage();
                }}>
                <div className="flex space-x-2">
                  <PlusCircleOutlined />
                  <span>Tambah Pesan Template Baru</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="space-y-4 rounded-xl bg-white p-6 shadow-md">
          <h3 className="m-0 flex items-center space-x-2 text-lg font-semibold">
            <span>Pesan Otomatis</span>
            <Tooltip
              title="Pesan yang dikirim secara otomatis oleh sistem. Apabila Anda mau edit, silakan kontak admin."
              className="ml-1 fill-black">
              <QuestionCircleOutlined />
            </Tooltip>
          </h3>
          <div className="space-y-4">
            {formItemWhatsappWidget.map((item, index) => (
              <FormItemWhatsApp
                index={index}
                item={item}
                SQEMPPrecodings={SQEMPPrecodings}
                selectedAutoMessage={selectedAutoMessage}
                templateOptions={templateOptions}
                handleAddSQEMPTemplate={handleAddSQEMPTemplate}
                handleSelectPrecodingAutoMessage={handleSelectPrecodingAutoMessage}
                handleSelecteTemplateChange={handleSelecteTemplateChange}
              />
            ))}
          </div>
        </div>
      </Form>
    </div>
  );
}
