import { useQuery } from 'react-query';
import { getDocsByConversationId } from '../../upload';
import { DocumentInterface } from '../../../components/form/workspace/workspace.interface';

interface IGetConversationDocument {
  accountId: string;
  conversationId: string;
  documents: DocumentInterface[];
}

export const useConversationDocument = (conversationId: string | undefined) => {
  const queryInfo = useQuery<IGetConversationDocument>(
    ['get-conversation-document', conversationId],
    () => getDocsByConversationId(conversationId!),
    {
      enabled: !!conversationId,
      retry: 1
    }
  );
  return queryInfo;
};
