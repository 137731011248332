import { ImageZoom, Title } from '@squantumengine/horizon';
import { Modal } from 'antd';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { ChooseTypeEnum } from '../kyc.interface';

function ModalExpandPhoto() {
  const { isExpandPhoto, setIsExpandPhoto, expandPhotoUrl, addPreviewType } =
    useSelectedCustomerStore();

  const title = addPreviewType === ChooseTypeEnum.KTP ? 'Foto KTP' : 'Foto KTP + Selfie';

  return (
    <Modal
      title={
        <Title level={3} className="m-0 p-0">
          {title}
        </Title>
      }
      open={isExpandPhoto}
      width={600}
      footer={null}
      forceRender
      centered
      onCancel={() => {
        setIsExpandPhoto(false);
      }}>
      <div className="h-[330px] w-[550px] bg-yellow-500">
        <ImageZoom src={expandPhotoUrl} alt="expand-photo" />
      </div>
    </Modal>
  );
}

export default ModalExpandPhoto;
