import { create } from 'zustand';
import { SelectedCustomerInterface } from '../shared/types/customer.interface';
import { SelectedCustomerStoreInterface } from './customer-state.interface';
import { DocumentInterface } from '../components/form/workspace/workspace.interface';
import { ChooseTypeEnum } from '../components/kyc/kyc.interface';

const { name, email, phone, gender, dateOfBirth } = JSON.parse(
  localStorage.getItem('customerDetails') || '{}'
);

export const useSelectedCustomerStore = create<SelectedCustomerStoreInterface>((set) => ({
  customerDetail: {
    contactId: '',
    name: name || '',
    email: email || '',
    phone: phone || '',
    gender: gender || '',
    dateOfBirth: dateOfBirth || '',
    motherMaidenName: '',
    note: ''
  },
  isPreClosedAvailable: false,
  isFinishKYC: false,
  isApprovedKYC: false,
  chooseSelfie: null,
  chooseKTP: null,
  fileKTP: null,
  fileSelfie: null,
  isLoadingUploadKYC: true,
  isFinishChooseKYC: false,
  metadata: { referenceId: '', communicationType: '' },
  dob: '',
  isManualKYCSubmited: false,
  ktpPreviews: [],
  selfiePreviews: [],
  isAddPreview: false,
  addPreviewType: ChooseTypeEnum.KTP,
  isChangePreview: false,
  changePreviewIndex: 0,
  isExpandPhoto: false,
  expandPhotoUrl: '',
  setIsManualKYCSubmited: (isManualKYCSubmited: boolean) => set({ isManualKYCSubmited }),
  setCustomerDetail: (customerDetail: SelectedCustomerInterface) => set({ customerDetail }),
  setIsPreClosedAvailable: (isPreClosedAvailable: boolean) => set({ isPreClosedAvailable }),
  setIsFinishKYC: (isFinishKYC: boolean) => set({ isFinishKYC }),
  setIsApprovedKYC: (isApprovedKYC: boolean) => set({ isApprovedKYC }),
  setChooseKTP: (chooseKTP: DocumentInterface | null) => set({ chooseKTP }),
  setChooseSelfie: (chooseSelfie: DocumentInterface | null) => set({ chooseSelfie }),
  setFileKTP: (fileKTP: File | null) => set({ fileKTP }),
  setFileSelfie: (fileSelfie: File | null) => set({ fileSelfie }),
  setIsLoadingUploadKYC: (isLoadingUploadKYC: boolean) => set({ isLoadingUploadKYC }),
  setIsFinishChooseKYC: (isFinishChooseKYC: boolean) => set({ isFinishChooseKYC }),
  setMetadata: (metadata: any) => set({ metadata }),
  setDob: (dob: string) => set({ dob }),
  setKtpPreviews: (ktpPreviews: DocumentInterface[]) => set({ ktpPreviews }),
  appendKtpPreviews: (ktpPreviews: DocumentInterface) =>
    set((state) => {
      const previews = [...state.ktpPreviews];
      if (previews.length >= 2) {
        previews.shift();
      }
      return { ktpPreviews: [...previews, ktpPreviews] };
    }),
  setSelfiePreviews: (selfiePreviews: DocumentInterface[]) => set({ selfiePreviews }),
  appendSelfiePreviews: (selfiePreviews: DocumentInterface) =>
    set((state) => {
      const previews = [...state.selfiePreviews];
      if (previews.length >= 2) {
        previews.shift();
      }
      return { selfiePreviews: [...previews, selfiePreviews] };
    }),
  setIsAddPreview: (isAddPreview: boolean) => set({ isAddPreview }),
  setAddPreviewType: (addPreviewType: ChooseTypeEnum) => set({ addPreviewType }),
  setIsChangePreview: (isChangePreview: boolean) => set({ isChangePreview }),
  setChangePreviewIndex: (changePreviewIndex: number) => set({ changePreviewIndex }),
  setIsExpandPhoto: (isExpandPhoto: boolean) => set({ isExpandPhoto }),
  setExpandPhotoUrl: (expandPhotoUrl: string) => set({ expandPhotoUrl }),
  resetAndSetKYCState: (ktp?: DocumentInterface | null, selfie?: DocumentInterface | null) => {
    set({
      chooseSelfie: selfie,
      chooseKTP: ktp,
      fileKTP: null,
      fileSelfie: null,
      isFinishKYC: false,
      isApprovedKYC: false,
      isFinishChooseKYC: false,
      isLoadingUploadKYC: true,
      isManualKYCSubmited: false,
      ktpPreviews: [],
      selfiePreviews: [],
      isAddPreview: false,
      addPreviewType: ChooseTypeEnum.KTP,
      isChangePreview: false,
      changePreviewIndex: 0,
      isExpandPhoto: false,
      expandPhotoUrl: ''
    });
  }
}));
