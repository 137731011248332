export enum FormTypeEnum {
  ACCOUNT = 'account',
  INBOX = 'inbox',
  SCHEDULE = 'schedule',
  USER = 'user',
  USER_INFO = 'user_info'
}

export enum FormActionEnum {
  CREATE = 'create',
  EDIT = 'edit',
  CHOOSE = 'choose',
  INFO = 'info'
}

export enum FormDuration {
  MIN_LOGOUT_DURATION = 2,
  MAX_LOGOUT_DURATION = 1440
}
