import Joyride from 'react-joyride';
import Tooltip from '../../../components/tour/layout';
import { LocalStorageKey } from '../../../static/local-storage';

const ButtonRefreshHighlight = () => {
  const steps: any = [
    {
      title: 'Refresh Conversation List',
      content: 'Refresh conversation list dengan mengklik tombol refresh.',
      target: '#button-refresh',
      disableBeacon: true
    }
  ];

  return (
    <Joyride
      callback={(val) => {
        if (val.action === 'reset')
          localStorage.setItem(LocalStorageKey.SQECC_CONV_REFRESH_BTN, 'clicked');
      }}
      continuous
      scrollToFirstStep
      showSkipButton
      showProgress={false}
      steps={steps}
      locale={{
        last: 'Tutup'
      }}
      styles={{
        options: {
          arrowColor: '#FFF',
          backgroundColor: '#FFF',
          primaryColor: '#1A936F',
          textColor: '#313131'
        }
      }}
      tooltipComponent={Tooltip}
    />
  );
};

export default ButtonRefreshHighlight;
