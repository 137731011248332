import { Button } from '@squantumengine/horizon';
import { ImageZoom } from '@squantumengine/horizon';

interface PreviewImageProps {
  src: string;
  alt: string;
  onChoose: () => void;
  onChange: () => void;
}

const PreviewImage = ({ src, alt, onChange, onChoose }: PreviewImageProps) => {
  return (
    <div className="overflow-hidden rounded-lg border border-solid border-neutral-200">
      <div className="image-zoom h-[230px] bg-yellow-500">
        <ImageZoom src={src} alt={alt} transformWrapperProps={{ maxScale: 5 }} />
      </div>

      <div className="grid grid-cols-2 border-0 border-t border-solid border-neutral-200">
        <Button
          variant="text"
          className="rounded-none border-0 border-r border-solid border-neutral-200 text-black hover:bg-neutral-100"
          onClick={onChoose}>
          Pilih
        </Button>
        <Button
          variant="text"
          className="rounded-none text-black hover:bg-neutral-100"
          onClick={onChange}>
          Ubah
        </Button>
      </div>
    </div>
  );
};

export default PreviewImage;
