/* eslint-disable no-console */
import { Button, Divider, Spin } from 'antd';
import { Icon } from '@squantumengine/horizon';
import { useChatListStore } from '../../../store/chat-list';
import Collapsible from '../../commons/Collapsible';
import { isNullOrUndefined } from '../../../utils/isNullUndefined';
import KYCResult from '../../kyc/components/kyc-result';
import { useCallStore } from '../../../store/call-state';
import { useGetVerdictKYC } from '../../../api/hooks/kyc/useGetVerdictKYC';

import BasicInfo from '../_partials/partial-info';
import PartialFiles from '../_partials/partial-files';
import HistoryList from '../_partials/history';
import { DoubleRightOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import KYCPage from '../../kyc';
import { KYCPageEnum } from '../../kyc/kyc.interface';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { useEffect, useState } from 'react';
import useLoadBerkas from '../../../hooks/useLoadBerkas';
import { useInboxStore } from '../../../store/new/inbox';

enum CollapseKey {
  CHAT = 'chat',
  DATA_PELANGGAN = 'data_pelanggan',
  INFORMASI = 'informasi',
  RIWAYAT = 'riwayat',
  BERKAS = 'berkas',
  RINGKASAN = 'isi_ringkasan'
}

type CollapsibleItem = {
  key: CollapseKey;
  label: JSX.Element | string;
  content: JSX.Element;
  activeKey?: string[];
};

function AgentWorkspace() {
  const { selectedCall } = useChatListStore();
  const { data: kycVerdict, isLoading } = useGetVerdictKYC(selectedCall);
  const { fullScreen, showChat, setShowChat } = useCallStore();
  const { isManualKYCSubmited } = useSelectedCustomerStore();
  const [isExpanded, setIsExpanded] = useState<boolean>(isManualKYCSubmited);
  const { isRefetchBerkas, handleReloadBerkas } = useLoadBerkas();
  const { isSelectedInboxManualKyc } = useInboxStore();

  const isManualKYC = isSelectedInboxManualKyc();

  const collapsibleItems = [
    {
      key: CollapseKey.DATA_PELANGGAN,
      label: <h3 className="heading m-0">Info Dasar</h3>,
      content: <BasicInfo />
    },
    {
      key: CollapseKey.INFORMASI,
      label: <h3 className="heading m-0">Lengkapi Informasi</h3>,
      content: (
        <div className="customer-form w-full">
          {isLoading ? (
            <div className="flex justify-center py-2 ">
              <Spin />
            </div>
          ) : isNullOrUndefined(kycVerdict) || !kycVerdict?.manualKyc ? (
            <KYCPage />
          ) : (
            <KYCResult kycVerdict={kycVerdict} />
          )}
        </div>
      )
    },
    {
      key: `${CollapseKey.RINGKASAN}`,
      label: <h3 className="heading m-0">Isi Ringkasan </h3>,
      content: <KYCPage defaultPage={KYCPageEnum.SUMMARY} />,
      activeKey: isExpanded ? `${CollapseKey.RINGKASAN}` : '',
      expandIcon: () => (
        <div
          className="flex items-center space-x-2 font-semibold"
          onClick={() => setIsExpanded(!isExpanded)}>
          <span>{isExpanded ? <UpOutlined /> : <DownOutlined />}</span>
        </div>
      )
    },
    {
      key: CollapseKey.BERKAS,
      label: (
        <h3 className="heading m-0 flex flex-row items-center">
          Berkas&nbsp;&nbsp;
          <div
            className={`${isRefetchBerkas && 'animate-spin'} flex cursor-pointer`}
            onClick={handleReloadBerkas}>
            <Icon name="sync" />
          </div>
        </h3>
      ),
      content: <PartialFiles />
    },
    {
      key: CollapseKey.RIWAYAT,
      label: <h3 className="heading m-0">Riwayat </h3>,
      content: <HistoryList />
    }
  ].reduce((acc, curr): any => {
    // @ts-ignore
    if (!([CollapseKey.INFORMASI, CollapseKey.RINGKASAN].includes(curr.key) && !isManualKYC))
      return [...acc, curr];
    return [...acc];
  }, []);

  const _renderCollapsibleItem = ({ key, label, content, ...rest }: CollapsibleItem) => (
    <div key={key}>
      <Collapsible itemKey={key} label={label} {...rest}>
        {content}
      </Collapsible>
      <Divider />
    </div>
  );

  const shouldShowHidePanel = isManualKYC && fullScreen && showChat;

  useEffect(() => {
    setIsExpanded(isManualKYCSubmited);
  }, [isManualKYCSubmited]);

  return (
    <div className="space-y-0">
      {shouldShowHidePanel && (
        <div className="flex items-center justify-between">
          <h3 className="heading">Kembali layar penuh</h3>
          <Button
            className="border-none"
            icon={<DoubleRightOutlined />}
            onClick={setShowChat.bind(null, false)}
          />
        </div>
      )}
      {collapsibleItems.map(_renderCollapsibleItem)}
    </div>
  );
}

export default AgentWorkspace;
