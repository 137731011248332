import ChooseResult from './choose-result';
import { ChooseTypeEnum, KYCVerdictResponse } from '../../kyc/kyc.interface';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { useEffect } from 'react';
import { useChatListStore } from '../../../store/chat-list';
import { isNullOrUndefined } from '../../../utils/isNullUndefined';
import { Paragraph } from '@squantumengine/horizon';
import { Flex } from 'antd';
import { getStatusClass, getStatusText } from '../../../utils/kyc';

function KYCResult({ kycVerdict }: { kycVerdict: KYCVerdictResponse | undefined }) {
  const { chooseKTP, chooseSelfie, setChooseKTP, setChooseSelfie, resetAndSetKYCState } =
    useSelectedCustomerStore();

  const { selectedCall } = useChatListStore();

  useEffect(() => {
    resetAndSetKYCState();
    setChooseKTP({
      documentName: 'ktp.png',
      documentUrl: kycVerdict?.ktpObjectName,
      documentId: kycVerdict?.ktpObjectName!
    });

    setChooseSelfie({
      documentName: 'selfie.png',
      documentUrl: kycVerdict?.selfieObjectName,
      documentId: kycVerdict?.selfieObjectName!
    });

    // eslint-disable-next-line
  }, [kycVerdict, selectedCall]);

  return (
    <Flex vertical gap={8}>
      {!isNullOrUndefined(kycVerdict) && (
        <>
          <ChooseResult data={chooseKTP!} isKYCSubmitted={true} name={ChooseTypeEnum.KTP} />
          <ChooseResult data={chooseSelfie!} isKYCSubmitted={true} name={ChooseTypeEnum.SELFIE} />
        </>
      )}
      <div className="flex flex-row justify-between gap-2">
        <Paragraph className="text-nowrap text-neutral-600">Status KYC:</Paragraph>
        <Paragraph className={`truncate ${getStatusClass(kycVerdict?.status!)}`}>
          {getStatusText(kycVerdict?.status!)}
        </Paragraph>
      </div>
      {!isNullOrUndefined(kycVerdict?.reason) && (
        <div className="flex flex-row justify-between gap-2">
          <Paragraph className="text-nowrap text-neutral-600">Alasan Penolakan:</Paragraph>
          <Paragraph className="truncate">{kycVerdict?.reason}</Paragraph>
        </div>
      )}
    </Flex>
  );
}

export default KYCResult;
