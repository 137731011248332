import { Divider, Spin } from 'antd';
import { Icon } from '@squantumengine/horizon';
import { useChatListStore } from '../../../store/chat-list';
import Collapsible from '../../commons/Collapsible';
import { isNullOrUndefined } from '../../../utils/isNullUndefined';
import KYCResult from '../../kyc/components/kyc-result';
import { useCallStore } from '../../../store/call-state';
import { useGetVerdictKYC } from '../../../api/hooks/kyc/useGetVerdictKYC';

import BasicInfo from '../_partials/partial-info';
import PartialFiles from '../_partials/partial-files';
import HistoryList from '../_partials/history';
import KYCPage from '../../kyc';
import { KYCPageEnum } from '../../kyc/kyc.interface';
import useLoadBerkas from '../../../hooks/useLoadBerkas';

enum CollapseKey {
  CHAT = 'chat',
  DATA_PELANGGAN = 'data_pelanggan',
  INFORMASI = 'informasi',
  RIWAYAT = 'riwayat',
  BERKAS = 'berkas',
  RINGKASAN = 'isi ringkasan'
}

type CollapsibleItem = {
  key: CollapseKey;
  label: JSX.Element | string;
  content: JSX.Element;
};

function SPVWorkspace() {
  const { selectedCall } = useChatListStore();
  const { isManualKYCMode } = useCallStore();
  const { data: kycVerdict, isLoading } = useGetVerdictKYC(selectedCall);
  const { isRefetchBerkas, handleReloadBerkas } = useLoadBerkas();

  const collapsibleItems = [
    {
      key: CollapseKey.DATA_PELANGGAN,
      label: <h3 className="heading m-0">Info Dasar</h3>,
      content: <BasicInfo />
    },
    {
      key: CollapseKey.INFORMASI,
      label: <h3 className="heading m-0">Lengkapi Informasi</h3>,
      content: (
        <div className="customer-form w-full">
          {isLoading ? (
            <div className="flex justify-center py-2 ">
              <Spin />
            </div>
          ) : isNullOrUndefined(kycVerdict) || !kycVerdict?.manualKyc ? (
            <KYCPage />
          ) : (
            <KYCResult kycVerdict={kycVerdict} />
          )}
        </div>
      )
    },
    {
      key: CollapseKey.RINGKASAN,
      label: <h3 className="heading m-0">Isi Ringkasan </h3>,
      content: <KYCPage defaultPage={KYCPageEnum.SUMMARY} />
    },
    {
      key: CollapseKey.BERKAS,
      label: (
        <h3 className="heading m-0 flex flex-row items-center">
          Berkas&nbsp;&nbsp;
          <div
            className={`${isRefetchBerkas && 'animate-spin'} flex cursor-pointer`}
            onClick={handleReloadBerkas}>
            <Icon name="sync" />
          </div>
        </h3>
      ),
      content: <PartialFiles />
    },
    {
      key: CollapseKey.RIWAYAT,
      label: <h3 className="heading m-0">Riwayat </h3>,
      content: <HistoryList />
    }
  ].reduce((acc, curr): any => {
    if (!([CollapseKey.INFORMASI, CollapseKey.RINGKASAN].includes(curr.key) && !isManualKYCMode))
      return [...acc, curr];
    return [...acc];
  }, []);

  const _renderCollapsibleItem = ({ key, label, content }: CollapsibleItem) => (
    <div key={key}>
      <div>
        <Collapsible key={key} label={label}>
          {content}
        </Collapsible>
      </div>
      <Divider />
    </div>
  );

  return <div className="space-y-0">{collapsibleItems.map(_renderCollapsibleItem)}</div>;
}

export default SPVWorkspace;
