import { useEffect, useState } from 'react';
import { Col, Row, Form, Input, Button, Spin, Alert, Select, Tooltip } from 'antd';

import { createAccount, updateAccount } from '../../../api';
import { useTeamStore } from '../../../store/team-state';
import { FormActionEnum, FormDuration } from '../../../static/team';

import { CaretDownOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { AccountIdentityEnum, accountIdentityOptions } from '../../../static/communication-type';
import ErrorForm from '../error-form/error-form';
import { CHANGE_ACCOUNT_IDENTIFIER } from '../../../static/error-message';
import { Paragraph } from '@squantumengine/horizon';

function BusinessUnitForm() {
  const [form] = Form.useForm();
  const { formAction, selectedBU, setIsOpenForm, fetchAccount } = useTeamStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const submitAccount = async () => {
    setLoading(true);
    const payload = {
      name: form.getFieldValue('accountName'),
      description: form.getFieldValue('description'),
      countryCode: form.getFieldValue('countryCode'),
      target: form.getFieldValue('agentTarget'),
      accountIdentity: form.getFieldValue('accountIdentity'),
      agentIdleTime: Number(form.getFieldsValue().agentIdleTime),
      allowedAgentIdleTime: !!form.getFieldsValue().agentIdleTime
    };

    try {
      if (formAction === FormActionEnum.CREATE) {
        await createAccount(payload);
      } else if (formAction === FormActionEnum.EDIT) {
        await updateAccount(form.getFieldValue('accountId'), payload);
      }
      form.resetFields();
      setIsOpenForm(false);
      fetchAccount();
    } catch (error: any) {
      setErrorMessage(
        error.response
          ? error.response?.data?.message
          : 'Terjadi kesalahan, silahkan coba lagi nanti'
      );
    } finally {
      setLoading(false);
    }
  };

  const disableFormSubmit = () => {
    const formValues = form.getFieldsValue();
    const descError = form.getFieldError('description');
    return (
      !formValues.accountName ||
      !formValues.description ||
      descError.length > 0 ||
      !formValues.countryCode
    );
  };

  useEffect(() => {
    const isSelectedBU = Object.keys(selectedBU).length !== 0 && formAction === FormActionEnum.EDIT;
    if (isSelectedBU) {
      form.setFieldsValue({
        accountName: selectedBU.name,
        description: selectedBU.description,
        countryCode: selectedBU.countryCode,
        agentTarget: selectedBU.target,
        accountId: selectedBU.accountId,
        agentIdleTime: selectedBU.accountConfig?.agentIdleTime,
        allowedAgentIdleTime: selectedBU.accountConfig?.allowedAgentIdleTime,
        accountIdentity: selectedBU.accountIdentity
      });
    } else {
      form.setFieldsValue({
        accountName: '',
        description: '',
        countryCode: '',
        agentTarget: '',
        agentIdleTime: 0,
        allowedAgentIdleTime: false,
        accountIdentity: AccountIdentityEnum.EMAIL
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, selectedBU]);

  return (
    <Spin spinning={loading}>
      <Row id="business_unit_form" className="rounded-xl bg-white p-6 pb-0">
        <Col span={24}>
          <Row justify="space-between" className="mb-4">
            <div className="text-2xl font-semibold">{`${
              formAction === FormActionEnum.CREATE ? 'Tambah' : 'Edit'
            } Unit Bisnis`}</div>
            <CloseOutlined className="cursor-pointer" onClick={() => setIsOpenForm(false)} />
          </Row>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            autoComplete="off"
            requiredMark={false}
            initialValues={{
              accountIdentity: AccountIdentityEnum.EMAIL
            }}
            size="large">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="accountName"
                  required
                  className="business_unit_form-name mb-4"
                  label={<div className="text-base font-semibold">Nama Unit Bisnis</div>}>
                  <Input placeholder="Asuransi Simas Jiwa" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="description"
                  required
                  className="business_unit_form-description mb-4"
                  label={<div className="text-base font-semibold">Deskripsi</div>}
                  rules={[{ max: 255 }]}>
                  <Input.TextArea
                    rows={3}
                    placeholder="Tulis penjelasan tentang Unit Bisnis"
                    showCount
                    maxLength={255}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="accountIdentity"
                  className="business_unit_form-identifier m-0"
                  label={
                    <>
                      <div className="text-base font-semibold">Penanda</div>
                      <Tooltip
                        title={
                          <>
                            <ul>
                              <li>
                                Email: Pelanggan akan mengisi data nama lengkap, nomor telepon, dan
                                email sebelum menggunakan Contact center.
                              </li>
                              <li>
                                Nomor HP: Pelanggan akan nama lengkap dan nomor HP sebelum
                                menggunakan Contact center.
                              </li>
                            </ul>
                          </>
                        }
                        color={'#2DB089'}>
                        <QuestionCircleOutlined className="ml-2 text-green-emerald-light" />
                      </Tooltip>
                    </>
                  }
                  required>
                  <Select
                    suffixIcon={<CaretDownOutlined style={{ fontSize: '1.5em' }} />}
                    disabled={formAction === FormActionEnum.EDIT}
                    className="w-full"
                    style={{ width: 120 }}
                    onChange={(value: string) => form.setFieldsValue({ accountIdentity: value })}
                    options={accountIdentityOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            {formAction === FormActionEnum.CREATE && (
              <ErrorForm isVisible={true} message={CHANGE_ACCOUNT_IDENTIFIER} />
            )}
            <Row>
              <Col span={24}>
                <Form.Item
                  name="countryCode"
                  required
                  className="business_unit_form-country my-4"
                  label={<div className="text-base font-semibold">Negara</div>}>
                  <Input placeholder="Negara" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="flex items-center gap-2">
                  <Form.Item
                    name="agentIdleTime"
                    required
                    className="business_unit_form-country my-4"
                    label={<div className="text-base font-semibold">Durasi Logout Otomatis</div>}>
                    <Input
                      type="number"
                      min={FormDuration.MIN_LOGOUT_DURATION}
                      max={FormDuration.MAX_LOGOUT_DURATION}
                      placeholder="Durasi"
                    />
                  </Form.Item>
                  &nbsp;
                  <Paragraph className="mt-8">Menit</Paragraph>
                </div>
              </Col>
            </Row>
            {formAction === FormActionEnum.EDIT && (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="accountId"
                    required
                    className="business_unit_form-country mb-4"
                    label={<div className="text-base font-semibold">Business Unit ID</div>}>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {errorMessage !== '' && (
              <Row className="mb-4">
                <Col span={24}>
                  <Alert className="capitalize" message={errorMessage} type="error" showIcon />
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24}>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      className={`business_unit_form-submit rounded-3xl ${
                        disableFormSubmit()
                          ? 'bg-grey-50 text-grey-20'
                          : 'bg-green-emerald hover:bg-green-emerald-light active:bg-green-emerald'
                      }`}
                      disabled={disableFormSubmit()}
                      block
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      onClick={submitAccount}>
                      <strong>
                        {formAction === FormActionEnum.CREATE ? '' : 'Ubah dan '} Simpan
                      </strong>
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
}

export default BusinessUnitForm;
