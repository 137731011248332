import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';
import { OperationalHour } from '../../../shared/types/operational-hour.interface';
import { ListItemProps } from './components/form-team-configuration/form-list-item';
import { ConversationContext } from '../../../types/new/inbox';
import { CreateTeamFormSection, ChannelEnum } from './type';
import {
  TemplateMessage,
  WhatsAppSupportModeEnum,
  WhatsappAttribute
} from '../../../shared/types/whatsapp.interface';

export enum FormTypeEnum {
  CREATE,
  EDIT
}

type FormDisplay = {
  greetingEnabled: boolean;
  greetingMessage: string;
  waitingRoomMessage: string;
  longQueueMessage: string;
  oooMessage: string;
  vbg: string;
};

type FormConfiguration = {
  inboxName: string;
  websiteUrl: string;
  greetingInfo: any;
  target: string;
  allowTextMessage?: boolean;
  allowMediaCall?: boolean;
  allowAttachment?: boolean;
  allowRefId?: boolean;
  allowConversationSummary?: boolean;
  allowConversationRating?: boolean;
  communicationModes?: Array<string>;
  conversationContext: ConversationContext;
  showVideoCallButton?: boolean;
  showAudioCallButton?: boolean;
  showScreenShareButton?: boolean;
  showCapturePhoto?: boolean;
  switchCamera?: boolean;
  autoConnect?: boolean;
  defaultCommunicationType?: string;
  webhookIdentifier?: string;
  credential?: string;
  supportMode?: WhatsAppSupportModeEnum;
  durationReminder: number;
};

export type FormWhatsAppWidget = {
  greetingEnabled: true;
  inboxName: string;
  channel: 'WHATSAPP';
  target: 10;
  templateMessages: TemplateMessage[];
  campaignConfigurations: WhatsappAttribute[];
  inboxConfig: {
    supportMode: WhatsAppSupportModeEnum;
    decisionTreeId: string;
  };
};

type TeamCreateEditStore = {
  inboxId?: string;
  setInboxId: (inboxId: string) => void;
  selectedSection: CreateTeamFormSection;
  setSelectedSection: (selectedSection: CreateTeamFormSection) => void;
  type: FormTypeEnum;
  setType: (type: FormTypeEnum) => void;
  channelType?: ChannelEnum;
  setChannelType: (channelType: ChannelEnum) => void;
  formTeamConfiguration: FormConfiguration;
  setFormTeamConfiguration: (formTeamConfiguration: FormConfiguration) => void;
  operationalHour: OperationalHour;
  setOperationalHour: (operationalHour: OperationalHour) => void;
  formDisplay: FormDisplay;
  setFormDisplay: (formDisplay: FormDisplay) => void;
  assignAgent: string[];
  setAssignAgent: (assignAgent: string[]) => void;
  topicList: ListItemProps[];
  setTopicList: (topicList: ListItemProps[]) => void;
  rejectReasonList: ListItemProps[];
  setRejectReasonList: (rejectReasonList: ListItemProps[]) => void;
  formWhatsapp: FormWhatsAppWidget;
  setFormWhatsapp: (formWhatsapp: FormWhatsAppWidget) => void;
  draft: Record<string, any>;
  setDraft: (uuid?: string) => string;
  applyDraft: (uuid: string) => void;
  resetStore: () => void;
};

const defaultOperationalHour: OperationalHour = {
  workingHoursEnabled: true,
  outOfOfficeMessage: '',
  workingHours: [1, 2, 3, 4, 5, 6, 7].map((n) => ({
    dayOfWeek: n,
    openHour: 9,
    openMinutes: 0,
    closeHour: 17,
    closeMinutes: 0,
    openAllDay: false,
    closedAllDay: n > 5
  }))
};

const defaultFormDisplay: FormDisplay = {
  vbg: '',
  greetingEnabled: false,
  greetingMessage: '',
  waitingRoomMessage: 'Mikrofon dan kamera Anda akan aktif untuk berbicara dengan staf kami',
  longQueueMessage: 'Mohon maaf, Advisor kami masih sibuk. Apoakah Anda bersedia menunggu?',
  oooMessage: 'Mohon maaf, kami belum dapat melayani Anda di luar jam operasional hari ini.'
};

const defaultFormWhatsapp: FormWhatsAppWidget = {
  greetingEnabled: true,
  inboxName: '',
  channel: 'WHATSAPP',
  target: 10,
  templateMessages: [],
  campaignConfigurations: [],
  inboxConfig: {
    supportMode: WhatsAppSupportModeEnum.BOT,
    decisionTreeId: ''
  }
};

export const CreateEditSections = [
  {
    key: CreateTeamFormSection.FORM_TEAM,
    name: 'Pengaturan Tim',
    description: 'Atur informasi tim dan pengaturan teknis'
  },
  {
    key: CreateTeamFormSection.FORM_ASSIGN_AGENT,
    name: 'Tugaskan Agen',
    description: 'Pilih agen yang bertugas di tim ini'
  },
  {
    key: CreateTeamFormSection.FORM_OPERATIONAL_SCHEDULE,
    name: 'Jadwal Operasional',
    description: 'Atur hari dan jam kerja agen'
  },
  {
    key: CreateTeamFormSection.FORM_DISPLAY,
    name: 'Tampilan Pelanggan',
    description: 'Atur virtual background agen saat video call dan pesan otomatis'
  }
];

const useTeamCreateEditStore = create<TeamCreateEditStore, any>(
  persist(
    (set, get) => ({
      inboxId: undefined,
      setInboxId: (inboxId: string) => set({ inboxId }),
      selectedSection: CreateTeamFormSection.FORM_TEAM,
      setSelectedSection: (selectedSection: CreateTeamFormSection) => set({ selectedSection }),
      type: FormTypeEnum.CREATE,
      setType: (type: FormTypeEnum) => set({ type }),
      channelType: undefined,
      setChannelType: (channelType: ChannelEnum) => set({ channelType }),
      formTeamConfiguration: {} as FormConfiguration,
      setFormTeamConfiguration: (formTeamConfiguration: any) => set({ formTeamConfiguration }),
      operationalHour: defaultOperationalHour,
      setOperationalHour: (operationalHour: OperationalHour) => set({ operationalHour }),
      formDisplay: defaultFormDisplay,
      setFormDisplay: (formDisplay: FormDisplay) => set({ formDisplay }),
      assignAgent: [],
      setAssignAgent: (assignAgent: string[]) => set({ assignAgent }),
      topicList: [],
      setTopicList: (topicList: ListItemProps[]) => set({ topicList }),
      rejectReasonList: [],
      setRejectReasonList: (rejectReasonList: ListItemProps[]) => set({ rejectReasonList }),
      formWhatsapp: defaultFormWhatsapp,
      setFormWhatsapp: (formWhatsapp: FormWhatsAppWidget) => set({ formWhatsapp }),
      draft: {},
      setDraft: (prevId?: string) => {
        const uuid = prevId || uuidv4();
        const data = get();
        const draft = { ...get().draft };
        const newDraftData = {
          formTeamConfiguration: data.formTeamConfiguration,
          operationalHour: data.operationalHour,
          formDisplay: data.formDisplay,
          assignAgent: data.assignAgent,
          topicList: data.topicList,
          rejectReasonList: data.rejectReasonList
        };
        draft[uuid] = newDraftData;
        set({ draft });
        return uuid;
      },
      applyDraft: (uuid: string) => {
        const data = get().draft[uuid];
        if (data) {
          const {
            formTeamConfiguration,
            operationalHour,
            formDisplay,
            assignAgent,
            topicList,
            rejectReasonList
          } = data;
          set({
            formTeamConfiguration,
            operationalHour,
            formDisplay,
            assignAgent,
            topicList,
            rejectReasonList
          });
        }
        return set({ draft: {} });
      },
      resetStore: () =>
        set({
          inboxId: undefined,
          selectedSection: CreateTeamFormSection.FORM_TEAM,
          type: FormTypeEnum.CREATE,
          channelType: undefined,
          formTeamConfiguration: {} as FormConfiguration,
          operationalHour: defaultOperationalHour,
          formDisplay: defaultFormDisplay,
          assignAgent: [],
          topicList: [],
          rejectReasonList: [],
          formWhatsapp: defaultFormWhatsapp
        })
    }),
    { name: 'store-team-create-edit' }
  )
);

export default useTeamCreateEditStore;
