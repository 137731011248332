import NativeLogin from './auth/native-login';
import SimasIDLogin from './auth/simasid-login';
import { LoginPlatform } from '../shared/types/auth.interface';
import { useEffect, useState } from 'react';
import { checkVideoAudio } from '../utils/webSocket/videoRequest';
import './login.css';
import { getUrlParam } from '../utils/url-search-params';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../store/new/auth';
import useMount from '../hooks/useMounth';
import Loading from '../components/login/loading';
import { LocalStorageKey } from '../static/local-storage';

function LoginPage() {
  const { authProvider } = useAuthStore();
  const [isWrongUrl, setIsWrongUrl] = useState(false);

  useEffect(() => {
    checkVideoAudio();
    //eslint-disable-next-line
  }, []);

  useMount(() => {
    const clientCode = getUrlParam('client');
    if (!clientCode) setIsWrongUrl(true);

    // show refresh button highlight on agent dashboard after login
    localStorage.removeItem(LocalStorageKey.SQECC_CONV_REFRESH_BTN);
  });

  if (isWrongUrl) return <Navigate to="/wrong-url" />;

  switch (authProvider) {
    case LoginPlatform.SIMAS_ID:
      return <SimasIDLogin />;
    case LoginPlatform.NATIVE:
      return <NativeLogin />;
    default:
      return <Loading />;
  }
}

export default LoginPage;
