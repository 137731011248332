import { useEffect } from 'react';
import { useChatListStore } from '../store/chat-list';
import { ChatMessageInterface } from '../components/card/chat-message/chat-message.interface';
import { MessageEventEnum } from '../static/message-event';

export const useGreetingMessage = (
  chatList: ChatMessageInterface[],
  setChatList: (chatList: ChatMessageInterface[]) => void,
  inboxDetail: any
) => {
  const { selectedCall } = useChatListStore();
  const conversationId = selectedCall?.conversationId || '';
  const greetingEnabled = inboxDetail?.greetingEnabled;

  useEffect(() => {
    const newChatList = [...chatList.sort((a, b) => a.time - b.time)];
    const greetingExist = chatList[0]?.id === 'greeting';

    if (greetingExist || !greetingEnabled) {
      return;
    }

    if (inboxDetail?.inboxId && conversationId) {
      const greetingMessage: ChatMessageInterface = {
        id: 'greeting',
        message: inboxDetail.greetingMessage,
        time: Date.now(),
        isUser: false,
        isBot: true,
        type: 'TEXT',
        conversationId: conversationId,
        event: MessageEventEnum.ACTIVE
      };

      if (chatList.length === 0) {
        setChatList([greetingMessage]);
        return;
      }

      greetingMessage.time = newChatList[0].time;
      newChatList.unshift(greetingMessage);
      setChatList(newChatList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatList, inboxDetail]);
};
