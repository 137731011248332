import { Fragment, useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Row, Collapse, Col, FormInstance, Form } from 'antd';
import ToggleBtn from './toggle-field';
import DropdownForm from './dropdown-field';
import {
  counterCloseConnection,
  durationReminderInput,
  mainDropdownList,
  mainToggleList,
  mediaToggleList,
  toggleReminderIdleConversation
} from '../../../static/inbox-config';
import CustomSmallTooltip from './custom-small-tooltip';
import { CommunicationTypeEnum, ConversationContextEnum } from '../../../static/communication-type';
import { Paragraph, TextField } from '@squantumengine/horizon';

function InboxSettings(props: { form: FormInstance<any> }) {
  const { form } = props;
  const { Panel } = Collapse;

  const toggleOptions = useMemo(
    () => mainToggleList(form),
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      form,
      Form.useWatch('allowTextMessage', form),
      Form.useWatch('allowMediaCall', form),
      Form.useWatch('allowAttachment', form),
      Form.useWatch('allowRefId', form),
      Form.useWatch('allowConversationSummary', form),
      Form.useWatch('allowConversationRating', form),
      Form.useWatch('defaultCommunicationType', form)
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  const toggleAllowMediaOptions = useMemo(
    () => mediaToggleList(form),
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      form,
      Form.useWatch('showVideoCallButton', form),
      Form.useWatch('showAudioCallButton', form),
      Form.useWatch('showScreenShareButton', form),
      Form.useWatch('showCapturePhoto', form),
      Form.useWatch('showSwitchCamera', form),
      Form.useWatch('autoConnect', form),
      Form.useWatch('defaultCommunicationType', form)
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  const dropdownOptions = useMemo(
    () => mainDropdownList(form),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Form.useWatch('webhookIdentifier', form), Form.useWatch('credential', form)]
  );

  const toggleReminder = useMemo(
    () => toggleReminderIdleConversation(form),
    /* eslint-disable react-hooks/exhaustive-deps */
    [form, Form.useWatch('reminderIdleConversation', form)]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  const InputDurationReminder = useMemo(
    () => durationReminderInput(form),
    /* eslint-disable react-hooks/exhaustive-deps */
    [form, Form.useWatch('durationReminder', form)]
    /* eslint-enable react-hooks/exhaustive-deps */
  );
  const isVideoMode = form
    ?.getFieldValue('communicationModes')
    .includes(CommunicationTypeEnum.VIDEO);

  const isEnabletoggleReminder = form.getFieldValue('reminderIdleConversation');
  const isMKYC = form.getFieldValue('conversationContext') === ConversationContextEnum.KYC;
  const formValues = form.getFieldsValue();
  const minValueExceed = formValues.durationReminder < counterCloseConnection.minDuration;
  const maxValueExceed = formValues.durationReminder > counterCloseConnection.maxDuration;

  return (
    <>
      <Row align="middle" justify="space-between">
        <Collapse
          className="advance-settings w-full"
          ghost
          expandIconPosition="end"
          expandIcon={(panelProps) => {
            return <DownOutlined rotate={panelProps.isActive ? 180 : 0} />;
          }}>
          <Panel
            forceRender
            key="activity-access"
            header={<span className="text-base font-semibold">Izin akses pelanggan</span>}>
            <div className="flex flex-col gap-3">
              <Row align="middle" justify="space-between">
                {toggleOptions?.map((item) => {
                  return <Fragment key={item?.title}>{ToggleBtn(item)}</Fragment>;
                })}
              </Row>
              <Row
                align="middle"
                justify="space-between"
                className={`overflow-hidden transition-all duration-300 ${
                  isVideoMode ? 'h-72' : 'h-0'
                }`}>
                <Col span={24} className="flex flex-row">
                  <div className="text-base font-semibold">Pengaturan panggilan</div>
                  <CustomSmallTooltip text="Atur tombol yang ditampilkan di layar saat melakukan panggilan." />
                </Col>
                {toggleAllowMediaOptions?.map((item) => {
                  return <Fragment key={item?.title}>{ToggleBtn(item)}</Fragment>;
                })}
                <div className="my-2 w-full border-0 border-t border-solid border-grey-50" />
              </Row>
            </div>
          </Panel>
        </Collapse>
      </Row>
      <Row align="middle" justify="space-between">
        <Collapse
          className="advance-settings w-full"
          ghost
          expandIconPosition="end"
          expandIcon={(panelProps) => {
            return <DownOutlined rotate={panelProps.isActive ? 180 : 0} />;
          }}>
          <Panel
            key="other-settings"
            header={<span className="text-base font-semibold">Pengaturan lain</span>}>
            <div className="flex flex-col gap-3">
              <Row align="middle">
                {isMKYC &&
                  toggleReminder?.map((item) => {
                    return <Fragment key={item?.title}>{ToggleBtn(item)}</Fragment>;
                  })}
              </Row>
              <Row align="middle">
                {isMKYC &&
                  isEnabletoggleReminder &&
                  InputDurationReminder?.map((item, key) => {
                    return (
                      <Form.Item key={key} name="durationReminder" required className="mb-4 w-full">
                        <div className="flex w-full items-center justify-between">
                          <Paragraph className="ant-typography w-full text-nowrap text-base">
                            Durasi Pengingat
                          </Paragraph>
                          <div>
                            <div
                              id="modified-textfield"
                              className="border-hz-neutral-300 flex h-9 w-28 items-center gap-2 p-2 pr-2">
                              <TextField
                                full
                                value={item.formInstance.getFieldValue(item.title)}
                                name="durationReminder"
                                suffix={
                                  <Paragraph className="ant-typography text-base">menit</Paragraph>
                                }
                                placeholder="15"
                              />
                            </div>
                            {(maxValueExceed || minValueExceed) && (
                              <Paragraph
                                size="s"
                                className="flex w-full text-nowrap px-3 text-red-500">
                                {maxValueExceed ? 'Maks' : 'Min'}{' '}
                                {maxValueExceed
                                  ? counterCloseConnection.maxDuration
                                  : counterCloseConnection.minDuration}{' '}
                                menit
                              </Paragraph>
                            )}
                          </div>
                        </div>
                      </Form.Item>
                    );
                  })}
              </Row>
              <Row align="middle">
                {dropdownOptions?.map((item) => {
                  return <Fragment key={item?.title}>{DropdownForm(item)}</Fragment>;
                })}
              </Row>
            </div>
          </Panel>
        </Collapse>
      </Row>
    </>
  );
}

export default InboxSettings;
