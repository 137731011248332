import { Button } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { ChoosItemEnum, ChoosItemInterface } from '../kyc.interface';
import { getIconUrl } from '../../../utils/getIconUrl';

function ChooseItem(item: ChoosItemInterface) {
  const { onOpenSS, onOpenGallery, type, label, disabled } = item;
  const isGalleryMode = type === ChoosItemEnum.GALLERY;

  return (
    <div className="flex w-full flex-row items-center justify-between gap-4 hover:text-white">
      <Button
        disabled={disabled}
        type="primary"
        shape="default"
        htmlType="button"
        className="manual-kyc-btn self-center rounded-xl border-black hover:text-white"
        onClick={isGalleryMode ? onOpenGallery : onOpenSS}>
        <div className="flex flex-row items-center gap-2">
          <p className={`manual-kyc-p text-xs font-semibold xl:text-base`}>{label}</p>
          {isGalleryMode ? (
            <img src={getIconUrl('gallery.svg')} alt="gallery-icon" className="block h-5 w-5" />
          ) : (
            <CameraOutlined className="text-xl text-black" />
          )}
        </div>
      </Button>
    </div>
  );
}

export default ChooseItem;
